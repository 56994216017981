const dashboardConstants = {
  //Text constants
  MANAGE: 'Manage',

  TOTAL_LEADS: 'Total Leads',
  PENDING_ADMIN: 'Pending Admin',
  PENDING_REGIONAL: 'Pending Regional',
  RECRUITS_SIGNED: 'Recruits Signed',
  AGREEMENTS_SENT: 'Agreements Sent',

  NAME: 'Name',
  REPORTING_TO: 'Reporting To',
  PARTNERSHIP: 'Partnership',
  REGIONAL_MANAGER: 'Regional Manager',
  DIVISION_MANAGER: 'Division Manager',
  SENIOR_TEAM_LEADER: 'Senior Team Leader',
  TEAM_LEADER: 'Team Leader',
  RA_STATUS: 'RA Status',
  NDA_STATUS: 'NDA Status',
  AGMT_STATUS: 'AGMT Status',

  SEARCH: 'Search',
  SEARCH_RECRUITS: 'Search Recruits',

  ADD_LEAD: 'Add Lead',

  RECRUIT_INFO: 'Recruit Info',
  FIRST_NAME_LABEL: 'First Name',
  LAST_NAME_LABEL: 'Last Name',
  EMAIL_LABEL: 'Email',
  PHONE_LABEL: 'Phone',
  BIRTH_DATE_LABEL: 'Birth Date',
  SALES_PROGRAM_LABEL: 'Sales Program',
  LAST_4_OF_SOCIALS_LABEL: 'Last 4 of Social',
  RECRUITER_INFO: 'Recruiter Info',
  SELECT_RECRUITER_LABEL: 'Select Recruiter',
  RECRUITING_OFFICE_LABEL: 'Recruiting Office',

  AGREEMENTS: 'Agreements',
  GENERAL: 'General',
  ACTIVITY: 'Activity',
  NOTES: 'Notes',
  ADD_NEW: 'Add New',
  PENDING_TITLE: 'Pending',

  CONTRACT_NAME: 'Contract Name',
  STATUS: 'Status',
  RECRUIT_NAME: 'Recruit Name',
  RECRUIT_SIGNED: 'Recruit Signed',
  ONB_COMMS: 'ONB Comms',
  REGIONAL_SIGNED: 'Regional Signed',
  ADMIN_SIGNED: 'Admin Signed',
  RECRUITER: 'Recruiter',
  GENERAL_INFO: 'General Info',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',

  MY_PROFILE: 'My Profile',
  SETTINGS: 'Settings',
  SIGN_OUT: 'Sign out',
  OPEN_MENU: 'Open Menu',
  OPEN_USER_MENU: 'Open user menu',
  USER_MANAGEMENT: 'User Management',
  SALES_TEAM_MANAGEMENT: 'Team Management',
  ARCHIVED_LEADS: 'Archived Leads',

  ALL_LEADS: 'All Leads',
  MY_TREE: 'My Tree',
  ONBOARDING: 'Onboarding',
  SALES_OPERATIONS: 'Sales Operations',
  ARCHIVED_USERS: 'Archived Users',
  REP_APPROVAL: 'Rep Approval',

  FILTERS: 'Filters',
  EXPORT: 'Export',
  SHOW_CHARTS_AND_GRAPHS: 'Show charts and graphs',

  FILTERS_MODAL_TITLE: 'Page Filters',
  FILTER_LABEL_TEAM: 'Team',
  FILTER_LABEL_STATUS: 'Status',
  FILTER_LABEL_SORT_BY_STATUS: 'Sort by status',
  FILTER_LABEL_SELECT_A_TEAM: 'Select a team',
  FILTER_LABEL_DATE_RANGE: 'Date range',
  FILTER_LABEL_SELECT_A_DATE_RANGE: 'Select a date range',
  FILTER_LABEL_SEASON: 'Season',
  FILTER_LABEL_EXPERIENCE: 'Rep Experience',
  FILTER_LABEL_PROFILE: 'Profile',
  FILTER_LABEL_SUBMITTED: 'Is Submitted',
  FILTER_LABEL_WORKDAY: 'Workday',
  FILTER_CHOICE_VIEW_ALL: 'View All',
  FILTER_CHOICE_ALL_TIME: 'All-time',
  FILTER_CHOICE_YESTERDAY: 'Yesterday',
  FILTER_CHOICE_TODAY: 'Today',
  FILTER_CHOICE_LAST_7_DAYS: 'Last 7 Days',
  FILTER_CHOICE_LAST_14_DAYS: 'Last 14 Days',
  FILTER_CHOICE_LAST_60_DAYS: 'Last 60 Days',
  FILTER_CHOICE_LAST_90_DAYS: 'Last 90 Days',
  FILTER_CHOICE_CUSTOM_RANGE: 'Custom Range',
  FILTER_CHOICE_PRESEASON: 'Preseason',
  FILTER_CHOICE_REGULAR_SEASON: 'Regular Season',
  FILTER_CHOICE_POSTSEASON: 'Postseason',
  FILTER_CHOICE_INCOMPLETE: 'Incomplete',
  FILTER_CHOICE_STARTED: 'Started',
  FILTER_CHOICE_COMPLETE: 'Complete',
  FILTER_CHOICE_ASSIGNED: 'Assigned',
  FILTER_ACTION_DELETE: 'Delete',
  FILTER_ACTION_CANCEL: 'Cancel',
  FILTER_ACTION_APPLY_RANGE: 'Apply Range',
  FILTER_ACTION_RESET_FILTERS: 'Reset Filters',
  FILTER_ACTION_APPLY_FILTERS: 'Apply Filters',
  FILTER_CHOICE_SUBMITTED: 'Submitted',
  FILTER_CHOICE_NOT_SUBMITTED: 'Not Submitted',

  BOX_PROFILE_COMPLETED: 'Profile completed',
  BOX_PROFILE_COMPLETED_TOOLTIP: 'These are reps that have completed 100% of their Onboarding Wizard.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_REP_NOT_SUBMITTED: 'Not Submitted ',
  BOX_REP_NOT_SUBMITTED_TOOLTIP: 'These are reps that have not submitted their profile.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_WORKDAY_COMPLETED: 'Workday completed',
  BOX_WORKDAY_COMPLETED_TOOLTIP: 'These are reps that have completed all of their HR documents.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_REPS_STATUS: 'Reps status',
  BOX_REPS_STATUS_TOOLTIP: 'This is a visual to display total gross number of reps <br>(all reps regardless of active status) vs. active reps.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_REPS_STATUS_STARTED: 'Started',
  BOX_REPS_STATUS_NOT_STARTED: 'Not started',
  BOX_ROOKIES: 'Rookies',
  BOX_ROOKIES_TOOLTIP: 'This is the total number of rookies.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_PROFILE_AND_WORKDAY_COMPLETE: 'Complete',
  BOX_PROFILE_AND_WORKDAY_COMPLETE_TOOLTIP: 'Completed their Profiles and Workday Tasks.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_ASSIGNED_TO_A_TEAM: 'Assigned to a team',
  BOX_ASSIGNED_TO_A_TEAM_TOOLTIP: 'This is the total number of reps assigned to a team.<br><span class="text-red-500">*May be affected by filters.</span>',
  BOX_REPS_STARTED: 'Reps started {range}',
  BOX_REPS_STARTED_TOOLTIP: 'This is the number of Reps that have started Onboarding.<br><span class="text-red-500">*May be affected by filters.</span>',

  RA_STATUS_TOOLTIP: 'Recruit Acknowledgement Status',
  NDA_STATUS_TOOLTIP: 'Non-Disclosure Agreement Status',
  AGMT_STATUS_TOOLTIP: 'Agreement Status',
  ONB_COMMS_TOOLTIP: 'Onboarding SMS & Email',

  SEARCH_REPS: 'Search Users',
  NO_DATA_TO_DISPLAY: 'No data to display',

  COLUMN_TEXT_USER_ID: 'User ID',
  COLUMN_TEXT_LEAD_ID: 'Lead ID',

  COLUMN_TEXT_STATUS: 'Status',
  COLUMN_TEXT_REP_ID: 'Rep ID',
  COLUMN_TEXT_WORKDAY_ID: 'Workday ID',
  COLUMN_TEXT_REP_NAME: 'Rep Name',
  COLUMN_TEXT_EMAIL: 'Email',
  COLUMN_TEXT_EXPERIENCE: 'Experience',
  COLUMN_TEXT_PHONE_NUMBER: 'Phone Number',
  COLUMN_MD_HOLDER: 'MD Holder',
  COLUMN_TEXT_WORKDAY: 'Workday',
  COLUMN_TEXT_PROFILE: 'Profile',
  COLUMN_TEXT_PROFILE_AND_WORKDAY_COMPLETE: 'Complete',
  COLUMN_ACTIONS: 'Actions',

  COLUMN_CV_VALUE: 'Contract Value (CV)',
  COLUMN_PAYMENT: 'Payment',

  STATUS_COMPLETE: 'Complete',
  STATUS_INCOMPLETE: 'Incomplete',

  TOTAL_SIGNED_REPS: 'Total Signed Reps',
  TRUE_SIGNED_REPS: 'True Signed Reps',
  NEEDS_INTERVIEW: 'Needs Interview',
  ONBOARDING_COMPLETE: 'Onboarding Complete',
  PENDING_CONTRACTS: 'Pending Contracts',
  COMPLETION: 'Completion',
  MANAGER_INTERVIEW: 'Manager Interview',
  PERSONAL_INFO: 'Personal Info',
  HOUSING_VEHICLE: 'Housing & Vehicle',
  UNIFORM_SWAG: 'Uniform & Swag',
  LICENSING: 'Licensing',
  HR_INFO: 'HR Info',
  GENDER_LABEL: 'Gender',
  MARITAL_STATUS_LABEL: 'Marital Status',

  TEAM_LEADER_LABEL: 'Team Leads',
  RECRUITER_LABEL: 'Recruiters',
  RECRUIT_LABEL: 'Recruits',
  SALES_POSITION_LABEL: 'Sales Position',

  ADDENDUM_NAME: 'addendum',
  ADDENDUM_LABEL: 'Addendum',
  EMAIL_NAME: 'email',
  PHONE_NAME: 'phone',
  SSN_LAST_FOUR_NAME: 'ssnLastFour',
  RECRUITING_OFFICE_NAME: 'recruiting_office_id',
  RECRUITER_NAME: 'recruiter_id',
  SENIOR_TEAM_LEADER_NAME: 'senior_team_leader_id', // fake: doesn't map to any API field
  DIVISION_MANAGER_NAME: 'division_manager_id', // fake: doesn't map to any API field
  REGIONAL_MANAGER_NAME: 'regional_manager_id', // fake: doesn't map to any API field
  PARTNERSHIP_NAME: 'partnership_id', // fake: doesn't map to any API field

  SALES_PROGRAM_NAME: 'sales_program',
  SALES_PROGRAM_FEATURE_FLAG_NAME: 'isSalesProgramEnabled',
  SALES_POSITION_NAME: 'experience',
  SALES_POSITION_FEATURE_FLAG_NAME: 'isSalesPositionEnabled',

  SUPER_ADMIN_GROUP: 1,
  MANAGERS_GROUP: 2,
  USERS_GROUP: 3,
  REGIONAL_MANAGEMENT_GROUP: 4,
  BRANCH_MANAGEMENT_GROUP: 5,
  DEALER_ADMIN_GROUP: 15,
  ROUTING_MANAGER_GROUP: 16,
  LICENSING_MANAGER_GROUP: 17,
  COMMUNICATIONS_ADMIN_GROUP: 18,

  SUPER_ADMIN_ROLE: 'Super Admin',
  DEALER_ADMIN_ROLE: 'Dealer Admin',
  REP_ROLE: 'Rep',
  BRANCH_MANAGER_ROLE: 'Branch Manager',
  TEAM_LEADER_ROLE: 'Team Leader',
  SENIOR_TEAM_LEADER_ROLE: 'Senior Team Leader',
  DIVISION_MANAGER_ROLE: 'Division Manager',
  REGIONAL_MANAGER_ROLE: 'Regional Manager',
  SENIOR_REGIONAL_ROLE: 'Senior Regional',
  VP_OF_SALES_ROLE: 'VP of Sales',
  PARTNERSHIP_ROLE: 'Partnership',

  ADMIN_LABEL: 'Admin',

  DELETE_USER_BUTTON: 'Archive User',
  RESTORE_USER_BUTTON: 'Restore User',

  DELETE_BUTTON: 'Delete',
  QUIT_CONFIRMATION_TITLE: 'Are you sure you want to quit?',
  QUIT_CONFIRMATION_MESSAGE: 'By continuing to cancel, you will lose all of your unsaved information. Are you sure you want to quit?',
  QUIT_CONFIRMATIONS_BUTTON: 'Quit without saving',
  DELETE_CONFIRMATION_TITLE: 'Are you sure you want to delete this contract?',
  DELETE_CONFIRMATIONS_BUTTON: 'Delete Contract',
  DELETE_USER_CONFIRMATION_TITLE: 'Are you sure you want to archive this user?',
  RESTORE_USER_CONFIRMATION_TITLE: 'Are you sure you want to restore this user?',

  ASC_ORDER: 'asc',
  DESC_ORDER: 'desc',
  ALL_LEADS_SEARCH_NAME: 'lead',
  ALL_LEADS_TYPE_FILTER_NAME: 'lead_type',
  RECRUIT_PROGRESS_SEARCH_NAME: 'recruit',
  RECRUIT_PROGRESS_TYPE_FILTER_NAME: 'recruit_type',
  REP_SEARCH_NAME: 'rep',
  REP_TYPE_FILTER_NAME: 'rep_type',
  USER_MANAGEMENT_SEARCH_NAME: 'user',
  REP_APPROVE_SEARCH_NAME: 'rep_to_approve',
  REP_STATUS_NAME: 'rep_status',

  NOT_COMING_STATUS: 'Not Coming',
  COMING_STATUS: 'Sales Rep',
  TERMINATED_STATUS: 'Sales Rep-Terminated',
  NOT_COMING_STATUS_TEXT: 'Sales Rep - Not coming',
  COMING_STATUS_TEXT: 'Sales Rep - Coming',
  TERMINATED_STATUS_TEXT: 'Sales Rep - Terminated',

  PROMOTION_BUILDER_FEATURE_FLAG_NAME: 'isPromotionBuilderEnabled',

  LEADS_EXPORT_FEATURE_FLAG_NAME: 'isLeadsExportEnabled',

  SPT_MODULE_FEATURE_FLAG_NAME: 'isSPTModuleEnabled',

  ARO_MODULE_FEATURE_FLAG_NAME: 'isAroModuleEnabled',

  ARO_PLANNING_PAGE_FEATURE_FLAG_NAME: 'isAroPlanningPageEnabled',

  LICENSING_MODULE_FEATURE_FLAG_NAME: 'isLicensingModuleEnabled',

  COMS_MODULE_FEATURE_FLAG_NAME: 'isCOMSModuleEnabled',

  DATA_DOG_RUM_LOGGING_ENABLED: 'isDatadogRumLoggingEnabled',

  CV_BASED_PAY_DETAILS_ENABLED: 'isCvBasedPayDetailsEnabled',

  CELL_RESOLUTIONS_ENABLED: 'isCellResolutionsEnabled',

  SALES_SETTINGS_BY_AREA_PAGE_ENABLED: 'isSalesSettingsByAreaEnabled',

};

export default dashboardConstants;
