import { onboardingConstants } from '@/lib/constants';
import { snakeCase, startCase } from 'lodash-es';

const {
  // Basic Info
  FIRST_NAME_LABEL,
  LAST_NAME_LABEL,
  FULL_LEGAL_NAME_LABEL,
  DATE_OF_BIRTH_LABEL,
  PHONE_NUMBER_LABEL,
  GENDER_LABEL,
  PRONOUN_LABEL,
  EXPERIENCE_LABEL,
  REP_STATUS_LABEL,

  // Marriage Info
  MARITAL_STATUS_LABEL,
  SPOUSE_FIRST_NAME_LABEL,
  SPOUSE_LAST_NAME_LABEL,

  //Pay Details
  SALES_RANK_LABEL,
  UPFRONT_PAY_LABEL,
  RENT_DEDUCTION_LABEL,
  UNIFORM_DEDUCTION_LABEL,
  RENT_DEDUCTION_REASON_LABEL,

  // Emergency Info
  EMERGENCY_CONTACT_NAME_LABEL,
  EMERGENCY_CONTACT_PHONE_NUMBER_LABEL,
  CURRENT_CITY_LABEL,

  // Address
  STREET_ADDRESS_LABEL,
  CITY_LABEL,
  STATE_PROVINCE_LABEL,
  ZIP_POSTAL_LABEL,
  COUNTRY_LABEL,
  IS_CURRENT_ADDRESS_DIFFER_LABEL,
  CURRENT_STREET_ADDRESS_LABEL,
  CURRENT_STATE_PROVINCE_LABEL,
  CURRENT_ZIP_POSTAL_LABEL,
  CURRENT_COUNTRY_LABEL,

  // Government Identification
  SSN_LABEL,
  DRIVER_LICENSE_NUMBER_LABEL,
  DRIVER_LICENSE_STATE_LABEL,
  DRIVER_LICENSE_EXPIRATION_DATE_LABEL,
  DRIVER_LICENSE_COUNTRY_LABEL,
  PASSPORT_EXPIRATION_DATE_LABEL,

  // Housing
  EXPECTED_ARRIVAL_DATE_LABEL,
  TENTATIVE_KNOCKING_START_LABEL,
  TENTATIVE_KNOCKING_END_LABEL,
  ACTUAL_START_DATE_LABEL,
  ACTUAL_END_DATE_LABEL,
  NEEDS_HOUSING_LABEL,
  HOUSING_TYPE_LABEL,
  NUMBER_OF_ROOMS_LABEL,
  REQUEST_ROOMMATE_LABEL,
  REP_ACKNOWLEDGMENT_LABEL,

  // Residential History
  ADDRESS_HISTORY_LABEL,
  ADDRESS_HISTORY_START_DATE_LABEL,
  ADDRESS_HISTORY_END_DATE_LABEL,

  // Vehicles
  HAS_VEHICLE_LABEL,
  VEHICLE_MODEL_LABEL,
  VEHICLE_COLOR_LABEL,
  VEHICLE_YEAR_LABEL,
  VEHICLE_PLATE_NUMBER_LABEL,
  VEHICLE_REGISTRATION_STATE_LABEL,
  VEHICLE_REGISTRATION_COUNTRY_LABEL,
  HAS_SEGWAY_LABEL,

  // Uniform
  SHIRT_SIZE_LABEL,
  JACKET_SIZE_LABEL,
  WAIST_SIZE_LABEL,
  HAT_SIZE_LABEL,
  SHOE_SIZE_LABEL,

  // Licensing Personal Details
  RACE_LABEL,
  HEIGHT_LABEL,
  WEIGHT_LABEL,
  HAIR_COLOR_LABEL,
  EYE_COLOR_LABEL,
  BIRTH_CITY_LABEL,
  BIRTH_STATE_LABEL,
  BIRTH_COUNTRY_LABEL,
  OTHER_BIRTH_STATE_LABEL,
  US_CITIZEN_LABEL,
  VISIBLE_MARKINGS_LABEL,
  MARKINGS_DESCRIPTION_ALT_LABEL,
  CONVICTED_CRIME_ALT_LABEL,
  CRIME_DESCRIPTION_ALT_LABEL,
  REP_EXPERIENCE_ALT_LABEL,

  // Rep Experience History
  REP_EXPERIENCE_HISTORY_LABEL,
  COMPANY_YEARS_SOLD_LABEL,
  COMPANY_NAME_LABEL,
  COMPANY_ACCOUNT_NUMBERS_LABEL,
  LAST_INDUSTRY_LABEL,
  LAST_INDUSTRY_OTHER_LABEL,
  CURRENT_SITUATION_LABEL,
  SOURCE_OF_DISCOVERY_LABEL,

  // Employment History
  EMPLOYMENT_HISTORY_LABEL,
  EMPLOYER_LABEL,
  START_DATE_LABEL,
  END_DATE_LABEL,

  // References
  REFERENCES_LABEL,
  REFERENCES_NAME_LABEL,
  RELATION_LABEL,

  // WOTC Tax Survey
  WOTC_TAX_SURVEY_COMPLETED_LABEL,

  //Workday
  WORKDAY_ID_LABEL,

  // Direct Deposit
  DIRECT_DEPOSIT_BANK_NAME_LABEL,
  DIRECT_DEPOSIT_ACCOUNT_NAME_LABEL,
  DIRECT_DEPOSIT_ACCOUNT_TYPE_LABEL,
  DIRECT_DEPOSIT_ROUTING_NUMBER_LABEL,
  DIRECT_DEPOSIT_ACCOUNT_NUMBER_LABEL,

  // W-9
  W9_LABEL,
  W9_STATUS_LABEL,
  W9_ENVELOPE_ID_LABEL,
  W9_REJECT_REASON_LABEL,
  W9_REJECT_COMMENT_LABEL,

  // W-9
  I9_LABEL,
} = onboardingConstants;

const fieldLabels = {
  // Basic Info
  first_name: FIRST_NAME_LABEL,
  last_name: LAST_NAME_LABEL,
  preferred_name: FULL_LEGAL_NAME_LABEL,
  dob: DATE_OF_BIRTH_LABEL,
  mobile: PHONE_NUMBER_LABEL,
  gender: GENDER_LABEL,
  pronoun_id: PRONOUN_LABEL,
  experience: EXPERIENCE_LABEL,
  label_name: REP_STATUS_LABEL,

  // Marriage Info
  marital_status: MARITAL_STATUS_LABEL,
  spouse_name: SPOUSE_FIRST_NAME_LABEL,
  spouse_last_name: SPOUSE_LAST_NAME_LABEL,

  //Pay Details
  sales_rank_id: SALES_RANK_LABEL,
  upfront_pay: UPFRONT_PAY_LABEL,
  rent_deduction: RENT_DEDUCTION_LABEL,
  uniform_deduction: UNIFORM_DEDUCTION_LABEL,
  rent_note: RENT_DEDUCTION_REASON_LABEL,

  // Emergency Info
  emergency_contact_name: EMERGENCY_CONTACT_NAME_LABEL,
  emergency_phone_number: EMERGENCY_CONTACT_PHONE_NUMBER_LABEL,

  // Address
  permanent_address: STREET_ADDRESS_LABEL,
  permanent_city: CITY_LABEL,
  permanent_state: STATE_PROVINCE_LABEL,
  permanent_zip: ZIP_POSTAL_LABEL,
  permanent_country: COUNTRY_LABEL,
  is_different_address: IS_CURRENT_ADDRESS_DIFFER_LABEL,
  address1: CURRENT_STREET_ADDRESS_LABEL,
  city: CURRENT_CITY_LABEL,
  state: CURRENT_STATE_PROVINCE_LABEL,
  zip: CURRENT_ZIP_POSTAL_LABEL,
  country: CURRENT_COUNTRY_LABEL,

  // Government Identification
  ss: SSN_LABEL,
  drivers_license_number: DRIVER_LICENSE_NUMBER_LABEL,
  state_issued: DRIVER_LICENSE_STATE_LABEL,
  drivers_license_expiration_date: DRIVER_LICENSE_EXPIRATION_DATE_LABEL,
  country_issued: DRIVER_LICENSE_COUNTRY_LABEL,
  passport_expiration_date: PASSPORT_EXPIRATION_DATE_LABEL,

  // Housing
  arrival_date: EXPECTED_ARRIVAL_DATE_LABEL,
  start_date: TENTATIVE_KNOCKING_START_LABEL,
  end_date: TENTATIVE_KNOCKING_END_LABEL,
  date_arrived: ACTUAL_START_DATE_LABEL,
  last_day: ACTUAL_END_DATE_LABEL,
  optin: NEEDS_HOUSING_LABEL,
  apartment_status_id: HOUSING_TYPE_LABEL,
  no_of_rooms: NUMBER_OF_ROOMS_LABEL,
  roommate_request: REQUEST_ROOMMATE_LABEL,
  rep_acknowledgment: REP_ACKNOWLEDGMENT_LABEL,

  // Residential History
  address: ADDRESS_HISTORY_LABEL,
  resident_from: ADDRESS_HISTORY_START_DATE_LABEL,
  resident_to: ADDRESS_HISTORY_END_DATE_LABEL,

  // Vehicles
  is_personal_vehicle: HAS_VEHICLE_LABEL,
  vehicle_model: VEHICLE_MODEL_LABEL,
  vehicle_color: VEHICLE_COLOR_LABEL,
  purchase_year: VEHICLE_YEAR_LABEL,
  license_plate_number: VEHICLE_PLATE_NUMBER_LABEL,
  license_state: VEHICLE_REGISTRATION_STATE_LABEL,
  license_country: VEHICLE_REGISTRATION_COUNTRY_LABEL,
  has_segway: HAS_SEGWAY_LABEL,

  // Uniform
  polo_shirt_size: SHIRT_SIZE_LABEL,
  jacket_size: JACKET_SIZE_LABEL,
  waist_size: WAIST_SIZE_LABEL,
  hat_size: HAT_SIZE_LABEL,
  shoe_size: SHOE_SIZE_LABEL,

  // Licensing Personal Details
  ethnicity: RACE_LABEL,
  height: HEIGHT_LABEL,
  weight: WEIGHT_LABEL,
  hair_color: HAIR_COLOR_LABEL,
  eye_color: EYE_COLOR_LABEL,
  place_of_birth: BIRTH_CITY_LABEL,
  birth_state: BIRTH_STATE_LABEL,
  birth_country: BIRTH_COUNTRY_LABEL,
  other_birth_state: OTHER_BIRTH_STATE_LABEL,
  is_us_citizen: US_CITIZEN_LABEL,
  has_visible_markings: VISIBLE_MARKINGS_LABEL,
  visible_markings_reason: MARKINGS_DESCRIPTION_ALT_LABEL,
  is_crime: CONVICTED_CRIME_ALT_LABEL,
  crime_description: CRIME_DESCRIPTION_ALT_LABEL,
  is_switchover: REP_EXPERIENCE_ALT_LABEL,

  // Rep Experience
  experiences: REP_EXPERIENCE_HISTORY_LABEL,
  last_industry: LAST_INDUSTRY_LABEL,
  last_industry_other: LAST_INDUSTRY_OTHER_LABEL,
  sales_company: COMPANY_NAME_LABEL,
  number_of_accounts: COMPANY_ACCOUNT_NUMBERS_LABEL,
  years_sold: COMPANY_YEARS_SOLD_LABEL,
  current_situation: CURRENT_SITUATION_LABEL,
  source_of_discovery: SOURCE_OF_DISCOVERY_LABEL,

  // Employment History
  employment: EMPLOYMENT_HISTORY_LABEL,
  employer: EMPLOYER_LABEL,
  service_from: START_DATE_LABEL,
  service_to: END_DATE_LABEL,

  // References
  references: REFERENCES_LABEL,
  name: REFERENCES_NAME_LABEL,
  relation: RELATION_LABEL,
  phone: PHONE_NUMBER_LABEL,

  // WOTC Tax Survey
  wotc_survey_completed: WOTC_TAX_SURVEY_COMPLETED_LABEL,

  // Workday
  workday_id: WORKDAY_ID_LABEL,

  // Direct Deposit
  bank_name: DIRECT_DEPOSIT_BANK_NAME_LABEL,
  account_name: DIRECT_DEPOSIT_ACCOUNT_NAME_LABEL,
  account_type: DIRECT_DEPOSIT_ACCOUNT_TYPE_LABEL,
  routing_transit_number: DIRECT_DEPOSIT_ROUTING_NUMBER_LABEL,
  account_number: DIRECT_DEPOSIT_ACCOUNT_NUMBER_LABEL,

  // W-9
  w9_status: W9_LABEL,
  status: W9_STATUS_LABEL,
  envelope_id: W9_ENVELOPE_ID_LABEL,
  reject_reason: W9_REJECT_REASON_LABEL,
  reject_comment: W9_REJECT_COMMENT_LABEL,

  // I-9
  i9_status: I9_LABEL,
};

const getFieldLabel = (key) => fieldLabels[key] ?? startCase(snakeCase(key));

export default getFieldLabel;
