import { createSelector } from 'reselect';

const manageSalesTeamsSlice = (state) => state.manageSalesTeams;

export const manageSalesTeamsSelector = createSelector(
  manageSalesTeamsSlice,
  (state) => state?.salesTeams || [],
);

export const manageSalesTeamsTotalSelector = createSelector(
  manageSalesTeamsSlice,
  (state) => state?.salesTeamsTotal,
);

export const salesAreasSelector = createSelector(
  manageSalesTeamsSlice,
  (state) => state?.salesAreas || [],
);

export const salesAreasTotalSelector = createSelector(
  manageSalesTeamsSlice,
  (state) => state?.salesAreasTotal,
);
