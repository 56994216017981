import PropTypes from 'prop-types';
import { useContext, createContext, useMemo, useCallback } from 'react';

import { salesSettingsByAreaSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';
import { selectAreasList } from '@/redux/areas-new';
import { useSelector } from 'react-redux';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';

const { TARGET_ID_VALUE } = planBuilderConstants;

const AreaContext = createContext();

const useGetAreaOptions = () => useContext(AreaContext);

const AreasProvider = ({ children }) => {
  const areas = useSelector(selectAreasList);
  const salesSettingsByArea = useSelector(salesSettingsByAreaSelector);

  const options = useMemo(() => {
    if (areas) {
      const areaIds = salesSettingsByArea.map(({ [TARGET_ID_VALUE]:target_id }) => target_id);

      return areas
        .filter(({ area_id }) => !areaIds.includes(area_id))
        .map(({ area_id, area_name }) => ({
          label: area_name,
          value: area_id,
        }));
    }

    return [];
  }, [areas, salesSettingsByArea]);

  const getOptions = useCallback(
    (area_id) => {
      const matchingArea = areas.find((area) => area.area_id === area_id);
      const baseOptions = [];

      if (matchingArea) {
        baseOptions.push({
          label: matchingArea.area_name,
          value: area_id,
        });
      }

      return [...baseOptions, ...options];
    },
    [areas, options],
  );

  return (
    <AreaContext.Provider value={getOptions}>{children}</AreaContext.Provider>
  );
};

AreasProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AreasProvider, useGetAreaOptions };
