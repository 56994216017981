import Api from '@/api';
import { createRequestSaga } from '@/redux/helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestSalesAreasAsync, requestSalesTeamsAsync } from '@/redux/sales-teams/actions';
import { addToastsAction } from '@/redux/toasts';
import { mapErrorToastsData } from '@/lib/api';

function* requestSalesTeamsSaga({ payload }) {
  const { successCallback, errorCallback, ...data } = payload;

  try {
    const response = yield call(
      Api.getSalesTeams(),
      { ...data },
    );

    yield put(requestSalesTeamsAsync.success(response));

    if (successCallback) {
      yield call(successCallback, response);
    }
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    if (errorCallback) {
      yield call(errorCallback, error);
    }

    throw error;
  }
}

function* requestSalesAreasSaga() {
  try {
    const pageSize = 1000;
    let page = 1;
    let areas = [];

    const response = yield call(
      Api.getSalesAreas(),
      {
        page: {
          size: pageSize,
          number: page,
        },
      },
    );
    const resultTotal = response.meta.total;
    const resultAreas = response.data;
    areas = areas.concat(resultAreas);

    while (areas.length < resultTotal) {
      page++;

      const additionalResponse = yield call(
        Api.getSalesAreas(),
        {
          page: {
            size: pageSize,
            number: page,
          },
        },
      );
      const resultAreas = additionalResponse.data;
      areas = areas.concat(resultAreas);
    }

    yield put(requestSalesAreasAsync.success({ salesAreas: areas, salesAreasTotal: resultTotal }));
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    throw error;
  }
}

export function* salesTeamsActionWatcher() {
  yield takeLatest(
    requestSalesTeamsAsync.request,
    createRequestSaga(requestSalesTeamsSaga, {
      keyNew: 'manageSalesTeams',
      errKey: 'manageSalesTeams',
      write: false,
    }),
  );
  yield takeLatest(
    requestSalesAreasAsync.request,
    createRequestSaga(requestSalesAreasSaga, {
      keyNew: 'areasForManageSalesTeams',
      errKey: 'areasForManageSalesTeams',
      write: false,
    }),
  );
}
