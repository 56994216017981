import * as yup from 'yup';
import subDays from 'date-fns/subDays';
import { onboardingConstants, onboardingDataValues } from '@/lib/constants';
import {
  YEAR_REGEX,
  REQUIRED,
  MIN_2_CHARS,
  MAX_255_CHARS,
  YEAR_DIGITS_MESSAGE,
  RESIDENTIAL_END_DATE_MESSAGE,
  ARRIVAL_DATE_MIN_MESSAGE,
  ARRIVAL_DATE_MAX_MESSAGE,
  KNOCKING_END_DATE_MIN_MESSAGE,
  INVALID_DATE_MESSAGE,
  ACTUAL_END_DATE_MIN_MESSAGE,
  ADDRESS_VALIDATION_MESSAGE,
} from '@/lib/validations';

const {
  YES_VALUE,
} = onboardingDataValues;

const {
  HOUSING_SECTION_FEATURE_FLAG_NAME,
  RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME,
  VEHICLES_SECTION_FEATURE_FLAG_NAME,

  // Housing Section
  NEEDS_HOUSING,
  HOUSING_TYPE,
  NUM_OF_ROOMS,
  REP_ACKNOWLEDGMENT,
  EXPECTED_ARRIVAL_DATE,
  TENTATIVE_KNOCKING_START_DATE,
  TENTATIVE_KNOCKING_END_DATE,
  ACTUAL_START_DATE,
  ACTUAL_END_DATE,

  // Residential History Section
  ADDRESS_HISTORY_NAME,
  ADDRESS_HISTORY_START_DATE,
  ADDRESS_HISTORY_END_DATE,

  // Vehicles Section
  HAS_VEHICLE,
  VEHICLE_MODEL,
  VEHICLE_COLOR,
  VEHICLE_PLATE_NUMBER,
  VEHICLE_YEAR,
  VEHICLE_REGISTRATION_COUNTRY,
  VEHICLE_REGISTRATION_STATE,
  HAS_SEGWAY,
} = onboardingConstants;

const conditionalVehicleSchema = yup
  .string()
  .when(HAS_VEHICLE, {
    is: (value) => value === YES_VALUE,
    then: yup
      .string()
      .checkWhiteSpacesOnly(REQUIRED)
      .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
        return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
      })
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: MIN_2_CHARS,
      })
      .max(255, MAX_255_CHARS),
    otherwise: yup.string().nullable().notRequired(),
  });

export const composeValidationSchema = (featureFlags) => {
  const {
    [HOUSING_SECTION_FEATURE_FLAG_NAME]: isHousingSectionEnabled,
    [RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME]: isResidentialHistorySectionEnabled,
    [VEHICLES_SECTION_FEATURE_FLAG_NAME]: isVehiclesSectionEnabled,
  } = featureFlags;

  return yup.object().shape({
    // Housing Section
    ...(isHousingSectionEnabled && {
      [EXPECTED_ARRIVAL_DATE]: yup
        .date()
        .typeError(INVALID_DATE_MESSAGE)
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin
            ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
            : schema.required(REQUIRED);
        })
        .when(TENTATIVE_KNOCKING_START_DATE, (knockingStartDate, schema) => {
          if (knockingStartDate && knockingStartDate instanceof Date && !isNaN(knockingStartDate)) {
            return schema.min(subDays(knockingStartDate, 3), ARRIVAL_DATE_MIN_MESSAGE);
          }

          return schema;
        })
        .when(TENTATIVE_KNOCKING_START_DATE, (knockingStartDate, schema) => {
          if (knockingStartDate && knockingStartDate instanceof Date && !isNaN(knockingStartDate)) {
            return schema.max(knockingStartDate, ARRIVAL_DATE_MAX_MESSAGE);
          }

          return schema;
        }),
      [TENTATIVE_KNOCKING_START_DATE]: yup
        .date()
        .typeError(INVALID_DATE_MESSAGE)
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin
            ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
            : schema.required(REQUIRED);
        }),
      [TENTATIVE_KNOCKING_END_DATE]: yup
        .date()
        .typeError(INVALID_DATE_MESSAGE)
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin
            ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
            : schema.required(REQUIRED);
        })
        .when(TENTATIVE_KNOCKING_START_DATE, (knockingStartDate, schema) => {
          if (knockingStartDate && knockingStartDate instanceof Date && !isNaN(knockingStartDate)) {
            return schema.min(knockingStartDate, KNOCKING_END_DATE_MIN_MESSAGE);
          }

          return schema;
        }),
      [ACTUAL_START_DATE]: yup
        .date()
        .nullable()
        .transform((value) => (value instanceof Date && !isNaN(value) ? value : null)),
      [ACTUAL_END_DATE]: yup
        .date()
        .typeError(INVALID_DATE_MESSAGE)
        .when(ACTUAL_START_DATE, (actualStartDate, schema) => {
          if (actualStartDate && actualStartDate instanceof Date && !isNaN(actualStartDate)) {
            return schema.min(actualStartDate, ACTUAL_END_DATE_MIN_MESSAGE);
          }

          return schema;
        })
        .nullable()
        .transform((value) => (value instanceof Date && !isNaN(value) ? value : null)),
      [NEEDS_HOUSING]: yup
        .string()
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
        })
        .nullable(),
      [HOUSING_TYPE]: yup
        .string()
        .when(NEEDS_HOUSING, (needsHousing, schema) => {
          if (needsHousing === YES_VALUE) {
            return schema.required(REQUIRED);
          }
        })
        .nullable(),
      [NUM_OF_ROOMS]: yup
        .string()
        .when(NEEDS_HOUSING, (needsHousing, schema) => {
          if (needsHousing === YES_VALUE) {
            return schema.required(REQUIRED);
          }
        })
        .nullable(),
      [REP_ACKNOWLEDGMENT]: yup
        .boolean()
        .when(NEEDS_HOUSING, (needsHousing, schema) => {
          if (needsHousing === YES_VALUE) {
            return schema.oneOf([true], REQUIRED);
          }
        }),
    }),

    // Residential History Section
    ...(isResidentialHistorySectionEnabled && {
      [ADDRESS_HISTORY_NAME]: yup
        .string()
        .checkWhiteSpacesOnly(REQUIRED)
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
        })
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: MIN_2_CHARS,
        })
        .checkAddress(ADDRESS_VALIDATION_MESSAGE.replace(':address:', 'Address'))
        .max(255, MAX_255_CHARS),
      [ADDRESS_HISTORY_START_DATE]: yup
        .date()
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin
            ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
            : schema.required(REQUIRED);
        })
        .typeError(INVALID_DATE_MESSAGE),
      [ADDRESS_HISTORY_END_DATE]: yup
        .date()
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin
            ? schema.nullable().transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
            : schema.required(REQUIRED);
        })
        .min(
          yup.ref(ADDRESS_HISTORY_START_DATE),
          RESIDENTIAL_END_DATE_MESSAGE,
        )
        .typeError(INVALID_DATE_MESSAGE),
    }),

    // Vehicles Section
    ...(isVehiclesSectionEnabled && {
      [HAS_VEHICLE]: yup
        .string()
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
        })
        .nullable(),
      [VEHICLE_MODEL]: conditionalVehicleSchema,
      [VEHICLE_COLOR]: conditionalVehicleSchema,
      [VEHICLE_PLATE_NUMBER]: conditionalVehicleSchema,
      [VEHICLE_YEAR]: yup
        .string()
        .when(HAS_VEHICLE, {
          is: (value) => value === YES_VALUE,
          then: yup
            .string()
            .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
              return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
            })
            .matches(YEAR_REGEX, {
              excludeEmptyString: true,
              message: YEAR_DIGITS_MESSAGE,
            }),
          otherwise: yup.string().nullable().notRequired(),
        }),
      [VEHICLE_REGISTRATION_COUNTRY]: yup
        .string()
        .when(HAS_VEHICLE, {
          is: (value) => value === YES_VALUE,
          then: yup
            .string()
            .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
              return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
            }),
        }),
      [VEHICLE_REGISTRATION_STATE]: yup
        .string()
        .when(HAS_VEHICLE, {
          is: (value) => value === YES_VALUE,
          then: yup
            .string()
            .when(VEHICLE_REGISTRATION_COUNTRY, {
              is: (value) => Boolean(value),
              then: yup.string().required(REQUIRED),
              otherwise: yup.string().when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
                return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
              }),
            }),
        }),
      [HAS_SEGWAY]: yup
        .string()
        .when(['$isAdmin', '$isWizard'], (isAdmin, isWizard, schema) => {
          return !isWizard && isAdmin ? schema : schema.required(REQUIRED);
        })
        .nullable(),
    }),
  });
};
