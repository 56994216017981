import { adminToolsSagas } from '@/modules/AdminTools/redux';
import { userManagementSagas } from '@/modules/dashboard/components/UsersManagement/redux';
import { housingSagas } from '@/modules/Housing/redux';
import { addressesActionWatcher } from '@/redux/addresses';
import { approvalActionWatcher } from '@/redux/approval';
import { areasActionWatcher } from '@/redux/areas-new';
import { authWatcher } from '@/redux/auth';
import { clusterStatsActionWatcher } from '@/redux/clusters';
import { contractsActionWatcher } from '@/redux/contracts';
import { filesActionWatcher } from '@/redux/files';
import { notificationsActionWatcher } from '@/redux/notifications';
import { onboardingActionWatcher } from '@/redux/onboarding';
import { polygonsActionWatcher } from '@/redux/polygons';
import { recruitersActionWatcher } from '@/redux/recruiters';
import { repsActionWatcher } from '@/redux/reps';
import { soActionWatcher } from '@/redux/sales-operations';
import { seasonsActionWatcher } from '@/redux/seasons';
import { teamsActionWatcher } from '@/redux/teams';
import { usersActionWatcher } from '@/redux/users';
import { all } from 'redux-saga/effects';
import { salesTeamsActionWatcher } from "@/redux/sales-teams";

const createRootSaga = () => function* () {
  yield all([
    authWatcher(),
    onboardingActionWatcher(),
    filesActionWatcher(),
    addressesActionWatcher(),
    areasActionWatcher(),
    teamsActionWatcher(),
    polygonsActionWatcher(),
    clusterStatsActionWatcher(),
    repsActionWatcher(),
    contractsActionWatcher(),
    recruitersActionWatcher(),
    seasonsActionWatcher(),
    soActionWatcher(),
    usersActionWatcher(),
    salesTeamsActionWatcher(),
    approvalActionWatcher(),
    notificationsActionWatcher(),
    ...adminToolsSagas(),
    ...housingSagas(),
    ...userManagementSagas(),
  ]);
};

export default createRootSaga;
