import { featureFlagsSelector } from '@/redux/onboarding';
import PropTypes from 'prop-types';
import { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { Actions, Menu, ProgressBar } from '.';
import { calculateWizardProgress } from '../../lib';

const Sidebar = ({
  isPersonalWizard,
  isPopup,
  isAdmin,
  isApproved,
  workdayComplete,
  menuItems,
  currentStep,
  setCurrentStep,
  setNextStep,
  userId,
  onUserDeleted,
}) => {
  const featureFlags = useSelector(featureFlagsSelector);

  const resolvedMenuItems = useMemo(() => {
    const resolveArguments = {
      ...featureFlags,
      isPersonalWizard,
    };

    return {
      ...menuItems,
      wizard: menuItems.wizard.map((item) => ({
        ...item,
        ...(item.resolve && item.resolve(resolveArguments)),
      })),
      extra: menuItems.extra.map((item) => ({
        ...item,
        ...(item.resolve && item.resolve(resolveArguments)),
      })),
    };
  }, [
    menuItems,
    featureFlags,
    isPersonalWizard,
  ]);

  const { progress, message } = useMemo(() => (
    calculateWizardProgress({
      menuItems: resolvedMenuItems,
      isApproved,
      workdayComplete,
      isAdmin,
    })
  ), [
    resolvedMenuItems,
    isApproved,
    workdayComplete,
    isAdmin,
  ]);

  return (
    <>
      <ProgressBar
        progress={progress}
        message={message}
      />
      <Menu
        items={resolvedMenuItems}
        isPopup={isPopup}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setNextStep={setNextStep}
      />
      <Actions
        isPersonalWizard={isPersonalWizard}
        isAdmin={isAdmin}
        userId={userId}
        onUserDeleted={onUserDeleted}
      />
    </>
  );
};

Sidebar.propTypes = {
  isPersonalWizard: PropTypes.bool.isRequired,
  isPopup: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  workdayComplete: PropTypes.bool.isRequired,
  menuItems: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  userId: PropTypes.number,
  onUserDeleted: PropTypes.func,
};

export default memo(Sidebar);
