import { dashboardConstants } from '@/lib';

export const getHeadRows = () => (
  [
    {
      value: 'Team ID',
      align: 'right',
      className: 'whitespace-nowrap',
    },
    {
      value: 'Team Name',
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: 'Area ID',
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: 'Area Name',
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: 'Visible',
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: 'Deleted',
      align: 'left',
      className: 'whitespace-nowrap',
    },
  ]
);

export const parseSalesTeamsRows = (rows, salesAreas) => (
  rows.length ? rows.map((row) => {
    const salesArea = salesAreas.find(( sales_area ) => sales_area.sales_area_id === row.sales_area_id);

    return [
      {
        value: row.sales_team_id,
        align: 'right',
      },
      {
        value: row.name ? row.name : '-',
        align: 'left',
      },
      {
        value: row.sales_area_id ? row.sales_area_id : '-',
        align: 'left',
      },
      {
        value: salesArea ? salesArea.name : '-',
        align: 'left',
      },
      {
        value: row.visible
          ? 'Yes'
          : 'No',
        align: 'left',
      },
      {
        value: row.deleted
          ? 'Yes'
          : 'No',
        align: 'left',
      },
    ];
  }) : [
    {
      value: dashboardConstants.NO_DATA_TO_DISPLAY,
      align: 'center',
      colSpan: 6,
      className: 'py-8',
    },
  ]
);
