import { useCallback, useMemo } from 'react';
import {
  createSalesSettingsAsync,
  removeSalesSettingsAsync,
  repsSelector,
  salesSettingsByRepSelector,
  updateSalesSettingsAsync,
} from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';
import RepSelect from './RepSelect';
import EditRep from './EditRep';
import EditDataTable from '@/modules/AdminTools/components/EditDataTable';
import { RepsProvider } from './RepContext';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';
import { object, number } from 'yup';
import { useSelector } from 'react-redux';

const {
  TARGET_CONTRACT_VALUE,
  TARGET_CONTRACT_VALUE_LABEL,
  TWENTY_FOUR_MONTH_THRESHOLD_VALUE,
  TWENTY_FOUR_MONTH_THRESHOLD_LABEL,
  TARGET_ID_VALUE,
  TARGET_TYPE_VALUE,
  REP_TARGET_TYPE_VALUE,
  REP_LABEL,
} = planBuilderConstants;

const fields = [
  {
    label: REP_LABEL,
    name: TARGET_ID_VALUE,
    CreateRender: RepSelect,
    Edit: EditRep,
  },
  {
    label: TWENTY_FOUR_MONTH_THRESHOLD_LABEL,
    name: TWENTY_FOUR_MONTH_THRESHOLD_VALUE,
    type: 'number',
    step: 0.01,
    required: false,
    min: 0,
    max: 100,
  },
  {
    label: TARGET_CONTRACT_VALUE_LABEL,
    name: TARGET_CONTRACT_VALUE,
    type: 'number',
    step: 0.01,
    required: false,
    min: 0,
    max: 999999,
  },
];

const schema = object({
  [TARGET_ID_VALUE]: number().required(),
  [TWENTY_FOUR_MONTH_THRESHOLD_VALUE]:
    number()
      .transform((value, originalValue) => (originalValue.toString().trim() === '' ? null : value))
      .max(100, `The ${TWENTY_FOUR_MONTH_THRESHOLD_LABEL} must be less than or equal to 100`)
      .min(0, `The ${TWENTY_FOUR_MONTH_THRESHOLD_LABEL} must be greater than or equal to 0`)
      .nullable(),
  [TARGET_CONTRACT_VALUE]:
    number()
      .transform((value, originalValue) => (originalValue.toString().trim() === '' ? null : value))
      .max(999999, `The ${TARGET_CONTRACT_VALUE_LABEL} must be less than or equal to 999999`)
      .min(0, `The ${TARGET_CONTRACT_VALUE_LABEL} must be greater than or equal to 0`)
      .nullable(),
});

const sortOrders = [TARGET_ID_VALUE, TWENTY_FOUR_MONTH_THRESHOLD_VALUE];

const title = 'Sales Settings by Rep';

const name = 'salesSettingsByRep';

const defaultValues = {
  [TARGET_TYPE_VALUE]: REP_TARGET_TYPE_VALUE,
  [TARGET_ID_VALUE]: '',
  [TWENTY_FOUR_MONTH_THRESHOLD_VALUE]: '',
  [TARGET_CONTRACT_VALUE]: '',
};

const searchPlaceholder = 'Search by rep';

const SalesSettingsByRep = () => {
  const reps = useSelector(repsSelector);

  const repNameMap = useMemo(() => {
    const map = {};
    reps.forEach(({ id, name }) => {
      map[id] = name;
    });

    return map;
  }, [reps]);

  const getSortField = useCallback(
    (sortingBy) => {
      if (sortingBy === TARGET_ID_VALUE) {
        return (salesSettingsByRep) => {
          const repId = salesSettingsByRep[TARGET_ID_VALUE];

          return repNameMap[repId];
        };
      }

      return (salesSettingsByRep) => salesSettingsByRep[sortingBy];
    },
    [repNameMap],
  );

  const getSearchField = ({ target_id }) => [repNameMap[target_id], target_id];

  return (
    <RepsProvider>
      <EditDataTable
        {...{
          sortOrders,
          searchPlaceholder,
          getSearchField,
          defaultValues,
          title,
          fields,
          schema,
          name,
          getSortField,
        }}
        updateAsync={updateSalesSettingsAsync}
        removeAsync={removeSalesSettingsAsync}
        createAsync={createSalesSettingsAsync}
        selector={salesSettingsByRepSelector}
      />
    </RepsProvider>
  );
};

export default SalesSettingsByRep;
