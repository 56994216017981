import { IconButton } from '@/components/common';
import { XIcon } from '@heroicons/react/outline';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';

const IFrameModal = ({ isOpen, onClose, url, title }) => {
  return (
    <ModalWrapper
      isOpened={isOpen}
      onCloseModal={onClose}
      width={'max-w-[1440px]'}
    >
      <div className="px-4 py-4 border-b border-gray-200 sm:px-6 min-w-[350px] min-h-[200px]">
        <div className="flex items-center justify-between pb-3">
          {title && (<h2 className="text-lg font-bold">{title}</h2>)}
          <IconButton
            actionTitle="Close image model"
            onClick={onClose}
            className="text-gray-500 hover:text-gray-900"
          >
            <XIcon className="w-6 h-6" aria-hidden="true"/>
          </IconButton>
        </div>
        <Iframe
          position="relative"
          display="block"
          url={url}
          styles={{ width: "100vw", maxWidth: "100%", height: "80vh" }}
        />
      </div>
    </ModalWrapper>
  );
};

IFrameModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
  title: PropTypes.node,
};

export default IFrameModal;
