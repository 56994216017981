import * as yup from 'yup';
import {
  ADDRESS_VALIDATION_MESSAGE,
  EMAIL_REGEX,
  EMAIL_VALIDATION_MESSAGE,
  MAX_11_CHARS,
  MAX_255_CHARS,
  MIN_2_CHARS,
  MIN_5_DIGITS,
  POSTAL_CODE_REGEX,
  REQUIRED,
  SSN_MASKED_REGEX,
  SSN_VALIDATION_MESSAGE,
} from '@/lib/validations';
import { onboardingConstants } from '@/lib/constants';

const {
  I9_FIRST_NAME,
  I9_LAST_NAME,
  I9_STREET_ADDRESS,
  I9_CITY,
  I9_STATE,
  I9_ZIP,
  I9_BIRTHDATE,
  I9_SSN,
  I9_EMAIL,
  I9_IMMIGRATION_STATUS,
} = onboardingConstants;

export const validationSchema = yup.object().shape({
  [I9_FIRST_NAME]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [I9_LAST_NAME]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [I9_STREET_ADDRESS]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS)
    .checkAddress(ADDRESS_VALIDATION_MESSAGE.replace(':address:', 'Address')),
  [I9_CITY]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [I9_STATE]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED),
  [I9_ZIP]: yup
    .string()
    .required(REQUIRED)
    .matches(POSTAL_CODE_REGEX, {
      excludeEmptyString: true,
      message: MIN_5_DIGITS,
    })
    .max(11, MAX_11_CHARS),
  [I9_BIRTHDATE]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED),
  [I9_SSN]: yup
    .string()
    .matches(SSN_MASKED_REGEX, {
      excludeEmptyString: true,
      message: SSN_VALIDATION_MESSAGE,
    })
    .nullable(),
  [I9_EMAIL]: yup
    .string()
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS)
    .test('email-regex',
      EMAIL_VALIDATION_MESSAGE,
      value => value && value.toString().match(EMAIL_REGEX)
    ),
  [I9_IMMIGRATION_STATUS]: yup
    .string()
    .required(REQUIRED)
    .nullable(),
});
