import { Collection, RepEditHistory } from '@/redux/models';
import { cloneDeep, isEqual } from 'lodash-es';
import { createSelector } from 'reselect';
import {
  validateHousingInfo,
  validateHrInfo,
  validateLicensingInfo,
  validatePersonalInfo,
  validateUniformInfo,
} from '@/lib/validations/submit';

import {
  onboardingConstants,
  onboardingDataValues,
  onboardingSidebar,
  pathConstants,
} from '@/lib/constants';

import {
  formatDate,
} from '@/lib';

const {
  PERSONAL_INFO_STEP_ID,
  HOUSING_VEHICLE_STEP_ID,
  UNIFORM_STEP_ID,
  LICENSING_STEP_ID,
  HR_STEP_ID,
  DOCUMENTS_STEP_ID,
  WORKDAY_TASK_STEP_ID,
  ATTACHMENTS_STEP_ID,
  ADMIN_VIEW_STEP_ID,
} = onboardingSidebar;

const {
  onboarding: {
    PERSONAL_INFO_PATH,
    HOUSING_VEHICLE_PATH,
    UNIFORM_SWAG_PATH,
    LICENSING_PATH,
    HR_PATH,
    DOCUMENTS_PATH,
    WORKDAY_TASKS_PATH,
    ATTACHMENTS_PATH,
    ADMIN_VIEW_PATH,
  },
} = pathConstants;

const {
  PERSONAL_INFO_FORM_TITLE,
  HOUSING_AND_VEHICLES_FORM_TITLE,
  UNIFORM_AND_SWAG_FORM_TITLE,
  LICENSING_FORM_TITLE,
  HR_INFO_FORM_TITLE,
  CONTRACTS,
  MY_CONTRACTS,
  WORKDAY_TASKS,
  ATTACHMENTS,
  ADMIN_VIEW_TITLE,
  CUSTOMER_FACING_PHONE_NUMBER_NAME,

  PERSONAL_INFO_FORM_NAME,
  HOUSING_AND_VEHICLES_FORM_NAME,
  UNIFORM_AND_SWAG_FORM_NAME,
  LICENSING_FORM_NAME,
  HR_INFO_FORM_NAME,

  HOUSING_SECTION_FEATURE_FLAG_NAME,
  RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME,
  VEHICLES_SECTION_FEATURE_FLAG_NAME,

  DIRECT_DEPOSIT_BANK_NAME,
  DIRECT_DEPOSIT_ACCOUNT_NAME,
  DIRECT_DEPOSIT_ACCOUNT_TYPE,
  DIRECT_DEPOSIT_ROUTING_NUMBER,
  DIRECT_DEPOSIT_ACCOUNT_NUMBER,
  DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER,

  CURRENT_SITUATION_NAME,
  SOURCE_OF_DISCOVERY_NAME,
  REP_EXPERIENCES_DATA_NAME,

  WORKDAY_ID,

  FIRST_NAME,
  LAST_NAME,
  FULL_NAME,
  DATE_OF_BIRTH,
  ADDRESS_CITY,
  ADDRESS_STATE,
  ADDRESS_ZIP,
  EXPERIENCE,
  ADDRESS_COUNTRY,

  HOUSING_TYPE,
  NUM_OF_ROOMS,
  ROOMMATE_REQUEST,
  TENTATIVE_KNOCKING_START_DATE,
  TENTATIVE_KNOCKING_END_DATE,
  ACTUAL_START_DATE,
  ACTUAL_END_DATE,
  RENT_TIER,
  OPT_IN,
  PRESEASON_HOUSING_TYPE,
  PRESEASON_NUM_OF_ROOMS,
  PRESEASON_TENTATIVE_KNOCKING_START_DATE,
  PRESEASON_TENTATIVE_KNOCKING_END_DATE,
  PRESEASON_ACTUAL_START_DATE,
  PRESEASON_ACTUAL_END_DATE,
  POSTSEASON_HOUSING_TYPE,
  POSTSEASON_NUM_OF_ROOMS,
  POSTSEASON_TENTATIVE_KNOCKING_START_DATE,
  POSTSEASON_TENTATIVE_KNOCKING_END_DATE,
  POSTSEASON_ACTUAL_START_DATE,
  POSTSEASON_ACTUAL_END_DATE,

  DRIVER_LICENSE_NUMBER,
  DRIVER_LICENSE_EXPIRATION_DATE,
  DRIVER_LICENSE_STATE_ISSUED,
  SOCIAL_SECURITY_NUMBER,
  LIMITED_LIABILITY_COMPANY,
  EMPLOYER_IDENTIFICATION_NUMBER,
  SOLICITOR_PERMIT_NUMBER,
  SOLICITOR_EXPIRATION_DATE,
  DRIVER_LICENSE_COUNTRY_ISSUED,

  SALES_RANK,
  PRONOUN,

  PENDING_APPROVAL_ITEMS_NAME,
  PROFILE_PICTURE_URL_NAME,
  PASSPORT_PICTURE_URL_NAME,
  DRIVER_LICENSE_URL_NAME,
  SOCIAL_SECURITY_URL_NAME,
} = onboardingConstants;

const onboardingSelector = (state) => state.onboarding;

export const selectUserIdSelected = (state) => state.onboarding.selected?.userId;

export const selectRecruitIdSelected = (state) => state.onboarding.selected?.recruitId;

export const selectIsOnboardingDataUpdated = (state) => state.onboarding.selected?.isUpdated;

export const selectedSelector = createSelector(
  onboardingSelector,
  (state) => state.selected,
);

export const profilePictureFileSelector = createSelector(
  selectedSelector,
  (state) => ({
    fileName: state?.profilePicture,
    file: state?.profilePictureLocalFile,
  }),
);

export const signatureFileSelector = createSelector(
  selectedSelector,
  (state) => ({
    fileName: state?.signature,
    file: state?.signatureLocalFile,
  }),
);

export const driverLicenseFileSelector = createSelector(
  selectedSelector,
  (state) => ({
    fileName: state?.driverLicense,
    file: state?.driverLicenseLocalFile,
  }),
);

export const passportFileSelector = createSelector(
  selectedSelector,
  (state) => ({
    fileName: state?.passportPicture,
    file: state?.passportPictureLocalFile,
  }),
);

export const socialSecurityFileSelector = createSelector(
  selectedSelector,
  (state) => ({
    fileName: state?.socialSecurityCard,
    file: state?.socialSecurityPictureLocalFile,
  }),
);

export const repOnboardedSelector = createSelector(
  selectedSelector,
  (state) => state?.onboarded,
);

export const repEditableSelector = createSelector(
  selectedSelector,
  (state) => state?.isEditable,
);

export const repApprovedSelector = createSelector(
  selectedSelector,
  (state) => state?.approved,
);

export const workdayCompleteSelector = createSelector(
  selectedSelector,
  (state) => state?.workdayComplete,
);

export const pendingApprovalItemsSelector = createSelector(
  selectedSelector,
  (state) => state?.[PENDING_APPROVAL_ITEMS_NAME] ?? [],
);

export const personalDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    firstName: state.firstName ?? '',
    lastName: state.lastName ?? '',
    fullName: state.fullName ?? '',
    dob: state.dob ?? '',
    gender: state.gender ?? '',
    [PRONOUN]: state[PRONOUN] ?? '',
    experience: state.experience ?? '',
    mobile: state.mobile ?? '',
    profilePicture: state.profilePicture ?? '',
    profilePictureLocalFile: state.profilePictureLocalFile ?? '',
    [PROFILE_PICTURE_URL_NAME]: state[PROFILE_PICTURE_URL_NAME] ?? '',
    [CUSTOMER_FACING_PHONE_NUMBER_NAME]: state[CUSTOMER_FACING_PHONE_NUMBER_NAME] ?? '',
  }),
);

export const marriageDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    isMarried: state.isMarried ?? '',
    spouseFirstName: state.spouseFirstName ?? '',
    spouseLastName: state.spouseLastName ?? '',
  }),
);

export const payDetailsSelector = createSelector(
  selectedSelector,
  (state) => ({
    [SALES_RANK]: state[SALES_RANK] ?? '',
    hasCvBasedPay: state.hasCvBasedPay ?? onboardingDataValues.NULL_VALUE,
    upfrontPay: state.upfrontPay ?? '0',
    rentDeduction: state.rentDeduction ?? '0.00',
    uniformDeduction: state.uniformDeduction ?? '0',
    rentNote: state.rentNote ?? '',
  }),
);

export const emergencyDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    emergencyContactName: state.emergencyContactName ?? '',
    emergencyContactPhoneNumber: state.emergencyContactPhoneNumber ?? '',
  }),
);

export const addressDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    isDifferentAddress: state.isDifferentAddress ?? '',
    addressOne: state.addressOne ?? '',
    addressCity: state.addressCity ?? '',
    addressState: state.addressState ?? '',
    addressZip: state.addressZip ?? '',
    addressCountry: state.addressCountry ?? '',
    currentAddressOne: state.currentAddressOne ?? '',
    currentAddressCity: state.currentAddressCity ?? '',
    currentAddressState: state.currentAddressState ?? '',
    currentAddressZip: state.currentAddressZip ?? '',
    currentAddressCountry: state.currentAddressCountry ?? '',
  }),
);

export const identificationDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    ssnNumber: state.ssnNumber ?? '',
    driverLicenseNumber: state.driverLicenseNumber ?? '',
    driverLicenseStateIssued: state.driverLicenseStateIssued ?? '',
    driverLicenseCountryIssued: state.driverLicenseCountryIssued ?? '',
    driverLicenseExpirationDate: state.driverLicenseExpirationDate ?? '',
  }),
);

export const socialMediaDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    facebookLink: state.facebookLink ?? '',
    linkedinLink: state.linkedinLink ?? '',
    twitterLink: state.twitterLink ?? '',
    instagramLink: state.instagramLink ?? '',
  }),
);

export const arrivalDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    expectedArrivalDate: state.expectedArrivalDate ?? '',
    tentativeKnockingStartDate: state.tentativeKnockingStartDate ?? '',
    tentativeKnockingEndDate: state.tentativeKnockingEndDate ?? '',
    actualStartDate: state.actualStartDate ?? '',
    actualEndDate: state.actualEndDate ?? '',
  }),
);

export const housingDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    needsHousing: state.needsHousing ?? onboardingDataValues.NO_VALUE,
    housingType: state.housingType ?? '',
    numOfRooms: state.numOfRooms ?? '',
    roommateRequest: state.roommateRequest ?? '',
    repAcknowledgment: state.repAcknowledgment ?? false,
  }),
);

export const residentialHistoryDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    addressHistoryName: state.addressHistoryName ?? '',
    addressHistoryStartDate: state.addressHistoryStartDate ?? '',
    addressHistoryEndDate: state.addressHistoryEndDate ?? '',
  }),
);

export const vehicleDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    hasVehicle: state.hasVehicle ?? '',
    vehicleModel: state.vehicleModel ?? '',
    vehicleColor: state.vehicleColor ?? '',
    vehicleYear: state.vehicleYear ?? '',
    vehiclePlateNumber: state.vehiclePlateNumber ?? '',
    vehicleRegistrationState: state.vehicleRegistrationState ?? '',
    vehicleRegistrationCountry: state.vehicleRegistrationCountry ?? 1,
    hasSegway: state.hasSegway ?? '',
  }),
);

export const uniformDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    shirtSize: state.shirtSize ?? '',
    jacketSize: state.jacketSize ?? '',
    waistSize: state.waistSize ?? '',
    hatSize: state.hatSize ?? '',
    shoeSize: state.shoeSize ?? '',
  }),
);

export const personalDetailDataSelector = createSelector(
  selectedSelector,
  (state) => ({
    race: state.race ?? '',
    feet: state.feet ?? '',
    inches: state.inches ?? '',
    weight: state.weight ?? '',
    hairColor: state.hairColor ?? '',
    eyeColor: state.eyeColor ?? '',
    cityOfBirth: state.cityOfBirth ?? '',
    stateOfBirth: state.stateOfBirth ?? '',
    countryOfBirth: state.countryOfBirth,
    stateOfBirthOther: state.stateOfBirthOther ?? '',
    isUsCitizen: state.isUsCitizen ?? '',
    hasVisibleMarkings: state.hasVisibleMarkings ?? '',
    markingsDescription: state.markingsDescription ?? '',
    isConvictedOfCrime: state.isConvictedOfCrime ?? '',
    crimeDescription: state.crimeDescription ?? '',
    hasRepExperience: state.hasRepExperience ?? '',
    [DRIVER_LICENSE_URL_NAME]: state[DRIVER_LICENSE_URL_NAME] ?? '',
    driverLicenseLocalFile: state.driverLicenseLocalFile ?? '',
    driverLicense: state.driverLicense ?? '',
    signature: state.signature ?? '',
    signatureLocalFile: state.signatureLocalFile ?? '',
  }),
);

export const repExperienceDataSelector = createSelector(
  selectedSelector,
  (state) => {
    const repExperiences = cloneDeep(state[REP_EXPERIENCES_DATA_NAME]);

    return {
      [CURRENT_SITUATION_NAME]: state[CURRENT_SITUATION_NAME] ?? '',
      [SOURCE_OF_DISCOVERY_NAME]: state[SOURCE_OF_DISCOVERY_NAME] ?? '',
      [REP_EXPERIENCES_DATA_NAME]: repExperiences,
    };
  },
);

export const employmentHistorySelector = createSelector(
  selectedSelector,
  (state) => {
    const cloned = cloneDeep(state.employmentData);

    return {
      employmentData: cloned,
    };
  },
);

export const referencesHistorySelector = createSelector(
  selectedSelector,
  (state) => {
    const cloned = cloneDeep(state.referenceData);

    return {
      referenceData: cloned,
    };
  },
);

export const hrDataSelector = createSelector(selectedSelector, (state) => ({
  passportPicture: state.passportPicture ?? '',
  passportPictureLocalFile: state.passportPictureLocalFile ?? '',
  [PASSPORT_PICTURE_URL_NAME]: state[PASSPORT_PICTURE_URL_NAME] ?? '',
  passportExpirationDate: state.passportExpirationDate ?? '',
  driverLicense: state.driverLicense ?? '',
  driverLicenseLocalFile: state.driverLicenseLocalFile ?? '',
  [DRIVER_LICENSE_URL_NAME]: state[DRIVER_LICENSE_URL_NAME] ?? '',
  socialSecurityCard: state.socialSecurityCard ?? '',
  socialSecurityPictureLocalFile: state.socialSecurityPictureLocalFile ?? '',
  [SOCIAL_SECURITY_URL_NAME]: state[SOCIAL_SECURITY_URL_NAME] ?? '',
  usesType: state.usesType ?? '',
  wotcSurveyCompleted: state.wotcSurveyCompleted ?? false,
  w9EnvelopeSent: state.w9EnvelopeSent ?? false,
  w9Clicked: state.w9Clicked ?? false,
  w9Submitted: state.w9Submitted ?? false,
  w9Completed: state.w9Completed ?? false,
  i9EnvelopeSent: state.i9EnvelopeSent ?? false,
  previousW9Exists: state.previousW9Exists ?? false,
  usePreviousW9: state.usePreviousW9 ?? false,
  i9Clicked: state.i9Clicked ?? false,
  i9Completed: state.i9Completed ?? false,
  i9Submitted: state.i9Submitted ?? false,
  [WORKDAY_ID]: state[WORKDAY_ID] ?? '',
  [DIRECT_DEPOSIT_BANK_NAME]: state[DIRECT_DEPOSIT_BANK_NAME] ?? '',
  [DIRECT_DEPOSIT_ACCOUNT_NAME]: state[DIRECT_DEPOSIT_ACCOUNT_NAME] ?? '',
  [DIRECT_DEPOSIT_ACCOUNT_TYPE]: state[DIRECT_DEPOSIT_ACCOUNT_TYPE] ?? '',
  [DIRECT_DEPOSIT_ROUTING_NUMBER]: state[DIRECT_DEPOSIT_ROUTING_NUMBER] ?? '',
  [DIRECT_DEPOSIT_ACCOUNT_NUMBER]: state[DIRECT_DEPOSIT_ACCOUNT_NUMBER] ?? '',
  [DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER]: state[DIRECT_DEPOSIT_CONFIRM_ACCOUNT_NUMBER] ?? '',

  // Private, immutable data: should not be shown to the user or modified
  _: {
    [DIRECT_DEPOSIT_ACCOUNT_NUMBER]: state._[DIRECT_DEPOSIT_ACCOUNT_NUMBER] ?? '',
  },
}));

export const repProfileSelector = createSelector(selectedSelector, (state) => ({
  [FIRST_NAME]: state?.firstName || '',
  [LAST_NAME]: state?.lastName || '',
  [FULL_NAME]: state?.fullName || '',
  [DATE_OF_BIRTH]: state?.dob ? formatDate(state.dob) : '',
  [ADDRESS_CITY]: state?.addressCity ? state.addressCity : '',
  [ADDRESS_STATE]: state?.addressState ? state.addressState : '',
  [ADDRESS_ZIP]: state?.addressZip ? state.addressZip : '',
  [ADDRESS_COUNTRY]: state?.addressCountry ? state.addressCountry : '',
  [EXPERIENCE]: state?.experience,
  [HOUSING_TYPE]: state?.housingType || '',
  [NUM_OF_ROOMS]: state?.numOfRooms || '',
  [ROOMMATE_REQUEST]: state?.roommateRequest || '',
  [RENT_TIER]: state?.rentTier || '',
  [OPT_IN]: Boolean(state?.optIn),
  [TENTATIVE_KNOCKING_START_DATE]: state?.tentativeKnockingStartDate ? formatDate(state.tentativeKnockingStartDate) : '',
  [TENTATIVE_KNOCKING_END_DATE]: state?.tentativeKnockingEndDate ? formatDate(state.tentativeKnockingEndDate) : '',
  [ACTUAL_START_DATE]: state?.actualStartDate ? formatDate(state.actualStartDate) : '',
  [ACTUAL_END_DATE]: state?.actualEndDate ? formatDate(state.actualEndDate) : '',
  [PRESEASON_HOUSING_TYPE]: state?.[PRESEASON_HOUSING_TYPE] ? state[PRESEASON_HOUSING_TYPE] : '',
  [PRESEASON_NUM_OF_ROOMS]: state?.[PRESEASON_NUM_OF_ROOMS] ? state[PRESEASON_NUM_OF_ROOMS] : '',
  [PRESEASON_TENTATIVE_KNOCKING_START_DATE]: state?.preseasonTentativeKnockingStartDate ? formatDate(state.preseasonTentativeKnockingStartDate) : '',
  [PRESEASON_TENTATIVE_KNOCKING_END_DATE]: state?.preseasonTentativeKnockingEndDate ? formatDate(state.preseasonTentativeKnockingEndDate) : '',
  [PRESEASON_ACTUAL_START_DATE]: state?.preseasonActualStartDate ? formatDate(state.preseasonActualStartDate) : '',
  [PRESEASON_ACTUAL_END_DATE]: state?.preseasonActualEndDate ? formatDate(state.preseasonActualEndDate) : '',
  [POSTSEASON_HOUSING_TYPE]: state?.[POSTSEASON_HOUSING_TYPE] ? state[POSTSEASON_HOUSING_TYPE] : '',
  [POSTSEASON_NUM_OF_ROOMS]: state?.[POSTSEASON_NUM_OF_ROOMS] ? state[POSTSEASON_NUM_OF_ROOMS] : '',
  [POSTSEASON_TENTATIVE_KNOCKING_START_DATE]: state?.postseasonTentativeKnockingStartDate ? formatDate(state.postseasonTentativeKnockingStartDate) : '',
  [POSTSEASON_TENTATIVE_KNOCKING_END_DATE]: state?.postseasonTentativeKnockingEndDate ? formatDate(state.postseasonTentativeKnockingEndDate) : '',
  [POSTSEASON_ACTUAL_START_DATE]: state?.postseasonActualStartDate ? formatDate(state.postseasonActualStartDate) : '',
  [POSTSEASON_ACTUAL_END_DATE]: state?.postseasonActualEndDate ? formatDate(state.postseasonActualEndDate) : '',

  [DRIVER_LICENSE_COUNTRY_ISSUED]: state?.driverLicenseCountryIssued || '',
  [DRIVER_LICENSE_NUMBER]: state?.driverLicenseNumber || '',
  [DRIVER_LICENSE_EXPIRATION_DATE]: state?.driverLicenseExpirationDate ? formatDate(state.driverLicenseExpirationDate) : '',
  [DRIVER_LICENSE_STATE_ISSUED]: state?.driverLicenseStateIssued || '',
  [SOCIAL_SECURITY_NUMBER]: state?.ssnNumber || '',
  [LIMITED_LIABILITY_COMPANY]: state?.limitedLiabilityCompany || '',
  [EMPLOYER_IDENTIFICATION_NUMBER]: state?.employerIdentificationNumber || '',
  [SOLICITOR_PERMIT_NUMBER]: state?.solicitorPermitNumber || '',
  [SOLICITOR_EXPIRATION_DATE]: state?.solicitorExpirationDate ? formatDate(state.solicitorExpirationDate) : '',
  profileImg: state?.profileImg || '',
  status: state?.status || '',
  currentAddressCity: state?.currentAddressCity || '',
  currentAddressState: state?.currentAddressState || '',
  currentAddressCountry: state?.currentAddressCountry || '',
  userCreatedAt: state?.userCreatedAt ? formatDate(state.userCreatedAt) : '',
  team: state?.team || '',
  userId: state?.userId || '',
  email: state?.email || '',
}));

export const featureFlagsSelector = createSelector(
  onboardingSelector,
  (state) => state?.featureFlags,
);

export const personalInfoCompletionSelector = createSelector(
  [
    personalDataSelector,
    marriageDataSelector,
    emergencyDataSelector,
    addressDataSelector,
    identificationDataSelector,
  ],
  (
    personalData,
    marriageData,
    emergencyData,
    addressData,
    idData,
  ) => validatePersonalInfo({
    ...personalData,
    ...marriageData,
    ...emergencyData,
    ...addressData,
    ...idData,
  }).personalInfoIsValid,
);

export const housingAndVehicleCompletionSelector = createSelector(
  [
    arrivalDataSelector,
    housingDataSelector,
    residentialHistoryDataSelector,
    vehicleDataSelector,
    featureFlagsSelector,
  ],
  (
    arrivalData,
    housingData,
    residentialData,
    vehicleData,
    featureFlags,
  ) => validateHousingInfo({
    ...arrivalData,
    ...housingData,
    ...residentialData,
    ...vehicleData,
  }, featureFlags).housingInfoIsValid,
);

export const uniformCompletionSelector = createSelector(
  [
    uniformDataSelector,
  ],
  (
    uniformData,
  ) => validateUniformInfo({
    ...uniformData,
  }).uniformInfoIsValid,
);

export const licensingCompletionSelector = createSelector(
  [
    personalDetailDataSelector,
    repExperienceDataSelector,
    employmentHistorySelector,
    referencesHistorySelector,
    featureFlagsSelector,
  ],
  (
    personal,
    experience,
    employment,
    references,
    featureFlags,
  ) => validateLicensingInfo({
    ...personal,
    ...experience,
    ...employment,
    ...references,
  }, featureFlags).licensingInfoIsValid,
);

export const hrCompletionSelector = createSelector(
  [
    hrDataSelector,
    featureFlagsSelector,
  ],
  (
    hrData,
    featureFlags,
  ) => validateHrInfo({
    ...hrData,
  }, featureFlags).hrInfoIsValid,
);

export const adminViewSelector = createSelector(
  onboardingSelector,
  (state) => state?.adminView,
);

const selectPersonalInfoCompleted = (state) => state
  ?.onboarding
  ?.onboardingCompletion
  ?.[PERSONAL_INFO_FORM_NAME] ?? false;

const selectHousingInfoCompleted = (state) => state
  ?.onboarding
  ?.onboardingCompletion
  ?.[HOUSING_AND_VEHICLES_FORM_NAME] ?? false;

const selectUniformInfoCompleted = (state) => state
  ?.onboarding
  ?.onboardingCompletion
  ?.[UNIFORM_AND_SWAG_FORM_NAME] ?? false;

const selectLicensingInfoCompleted = (state) => state
  ?.onboarding
  ?.onboardingCompletion
  ?.[LICENSING_FORM_NAME] ?? false;

const selectHrInfoCompleted = (state) => state
  ?.onboarding
  ?.onboardingCompletion
  ?.[HR_INFO_FORM_NAME] ?? false;

export const onboardingMenuItemsSelector = createSelector(
  [
    selectPersonalInfoCompleted,
    selectHousingInfoCompleted,
    selectUniformInfoCompleted,
    selectLicensingInfoCompleted,
    selectHrInfoCompleted,
  ],
  (
    isPersonalCompleted,
    isHousingCompleted,
    isUniformCompleted,
    isLicensingCompleted,
    isHrCompleted,
  ) => {
    const wizard = [
      {
        id: PERSONAL_INFO_STEP_ID,
        path: PERSONAL_INFO_PATH,
        label: PERSONAL_INFO_FORM_TITLE,
        shouldDisplay: true,
        completed: isPersonalCompleted,
      },
      {
        id: HOUSING_VEHICLE_STEP_ID,
        path: HOUSING_VEHICLE_PATH,
        label: HOUSING_AND_VEHICLES_FORM_TITLE,
        shouldDisplay: undefined,
        completed: isHousingCompleted,
        resolve: ({
          [HOUSING_SECTION_FEATURE_FLAG_NAME]: isHousingSectionEnabled,
          [RESIDENTIAL_HISTORY_SECTION_FEATURE_FLAG_NAME]: isResidentialHistorySectionEnabled,
          [VEHICLES_SECTION_FEATURE_FLAG_NAME]: isVehiclesSectionEnabled,
        }) => ({
          shouldDisplay: Object.values({
            isHousingSectionEnabled,
            isResidentialHistorySectionEnabled,
            isVehiclesSectionEnabled,
          }).some((value) => value === true),
        }),
      },
      {
        id: UNIFORM_STEP_ID,
        path: UNIFORM_SWAG_PATH,
        label: UNIFORM_AND_SWAG_FORM_TITLE,
        shouldDisplay: true,
        completed: isUniformCompleted,
      },
      {
        id: LICENSING_STEP_ID,
        path: LICENSING_PATH,
        label: LICENSING_FORM_TITLE,
        shouldDisplay: true,
        completed: isLicensingCompleted,
      },
      {
        id: HR_STEP_ID,
        path: HR_PATH,
        label: HR_INFO_FORM_TITLE,
        shouldDisplay: true,
        completed: isHrCompleted,
      },
    ];

    const extra = [
      {
        id: DOCUMENTS_STEP_ID,
        path: DOCUMENTS_PATH,
        label: undefined,
        shouldDisplay: true,
        resolve: ({ isPersonalWizard }) => ({
          label: isPersonalWizard ? MY_CONTRACTS : CONTRACTS,
        }),
      },
      {
        id: WORKDAY_TASK_STEP_ID,
        path: WORKDAY_TASKS_PATH,
        label: WORKDAY_TASKS,
        shouldDisplay: undefined,
        resolve: ({ isPersonalWizard }) => ({
          shouldDisplay: !isPersonalWizard,
        }),
      },
      {
        id: ATTACHMENTS_STEP_ID,
        path: ATTACHMENTS_PATH,
        label: ATTACHMENTS,
        shouldDisplay: true,
      },
      {
        id: ADMIN_VIEW_STEP_ID,
        path: ADMIN_VIEW_PATH,
        label: ADMIN_VIEW_TITLE,
        shouldDisplay: undefined,
        resolve: ({ isPersonalWizard }) => ({
          shouldDisplay: !isPersonalWizard,
        }),
      },
    ];

    return {
      wizard,
      extra,
      isWizardItem: (id) => Boolean(wizard.find(({ id: itemId }) => itemId === id)),
      resolveItemByPath: (path) => [...wizard, ...extra].find(({ path: itemPath }) => itemPath === path) ?? {},
    };
  },
  {
    memoizeOptions: {
      equalityCheck: isEqual,
    },
  },
);

export const currentStepSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding?.currentStep,
);

export const nextStepSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding?.nextStep,
);

export const repEditHistorySelector = createSelector(
  onboardingSelector,
  (onboarding) => new Collection(
    onboarding.editHistory.items.map((item) => new RepEditHistory(item)),
    onboarding.editHistory.total,
  ),
);

export const salesRanksSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding?.salesRanks ?? [],
);

export const pronounsSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding?.pronouns ?? [],
);
