import { useRequireAuth } from '@/hooks';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '@/redux/auth';

const AccessDenied = () => {
  useRequireAuth(useSelector(isAuthenticatedSelector));

  return (
    <main className="grow flex">
      <div className="max-w-[1440px] m-auto py-8 px-4 text-center">
        <div className="text-gray-300 font-black leading-none mb-2">
          <div className="text-9xl">401</div>
          <div className="text-6xl">Unauthorized</div>
        </div>
        <h1 className="text-3xl mb-2">You are not authorized to access this page.</h1>
        <p>If you believe this is an error, please contact an administrator.</p>
      </div>
    </main>
  );
};

export default AccessDenied;
