import { createAsyncAction, createReducer } from '@/redux/root';
import { nameSpace } from './nameSpace';
import { settingsReducer } from './settings-reducer';
import { settingsWatcher } from './settings-saga';
import { NAME, settingsSelector } from './settings-selector';
import { createSelector } from '@reduxjs/toolkit';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { addToastsAction } from '@/redux/toasts';
import { toastType } from '@/components';
import { HttpStatusCode } from 'axios';
import { createRequestSaga } from '@/redux/helpers';
import Api from '@/api';

const { AREA_TARGET_TYPE_VALUE, REP_TARGET_TYPE_VALUE } = planBuilderConstants;

export const salesSettingsNameSpace = `${nameSpace}/sales-settings`;

export const updateSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/UPDATE_SALES_SETTINGS`,
);

export const createSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/CREATE_SALES_SETTINGS`,
);

export const removeSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/REMOVE_SALES_SETTINGS`,
);

export const requestSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/REQUEST_SALES_SETTINGS`,
);

export const requestRepsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/REQUEST_SALES_SETTINGS_REPS`,
);

const SALES_SETTINGS = 'salesSettings';

const SALES_SETTINGS_REPS = 'salesSettingsReps';

export const salesSettingsReducer = {
  [SALES_SETTINGS]: settingsReducer(salesSettingsNameSpace, {
    updateAsync: updateSalesSettingsAsync,
    createAsync: createSalesSettingsAsync,
    removeAsync: removeSalesSettingsAsync,
    requestsAsync: requestSalesSettingsAsync,
  }),
  [SALES_SETTINGS_REPS]: createReducer(
    salesSettingsNameSpace,
    {
      [NAME]: [],
    },
    {
      [requestRepsAsync.success]: ({ state, action: { payload } }) => {
        state[NAME] = [...state[NAME].concat(
          payload.data.map(({ attributes }) => ({
            id: attributes.user_id,
            name: attributes.name,
          })).filter(({ id }) => !state[NAME].some((rep) => rep.id === id)),
        )];
      },
    },
  ),
};

export function* salesSettingsWatcher() {
  function* requestRepsSaga({ payload }) {
    const response = yield call(
      Api.getUsers(),
      { ...payload },
    );

    yield put(requestRepsAsync.success(response));
  }

  try {
    yield takeLatest(
      requestRepsAsync.request.type,
      createRequestSaga(requestRepsSaga, {
        keyNew: 'salesSettingsReps',
        errKey: 'salesSettingsReps',
        write: false,
      }),
    );

    yield settingsWatcher('sales-settings', {
      removeAsync: removeSalesSettingsAsync,
      updateAsync: updateSalesSettingsAsync,
      createAsync: createSalesSettingsAsync,
      requestsAsync: requestSalesSettingsAsync,
    });
  } catch (error) {
    const { response: { status, data: { message } } } = error;

    yield put(addToastsAction([{
      type: toastType.ERROR,
      message: status === HttpStatusCode.UnprocessableEntity
        ? message
        : 'Server failed to complete action',
      details: null,
    }]));

    throw error;
  }
}

export const salesSettingsSelector = settingsSelector(SALES_SETTINGS);

export const salesSettingsByAreaSelector = createSelector(
  salesSettingsSelector,
  (salesSettings) => salesSettings.filter((salesSetting) => salesSetting.target_type == AREA_TARGET_TYPE_VALUE),
);

export const salesSettingsByRepSelector = createSelector(
  salesSettingsSelector,
  (salesSettings) => salesSettings.filter((salesSetting) => salesSetting.target_type == REP_TARGET_TYPE_VALUE),
);

export const repsSelector = settingsSelector(SALES_SETTINGS_REPS);
