import {
  SalesSettingsByRep,
  Wrapper,
} from '@/modules/AdminTools/modules/SalesSettingsByRep';

import { abilityConstants } from '@/lib';

const { ACCESS_PLAN_BUILDER_ABILITY } = abilityConstants;

export const PATH = '/sales-settings-by-rep';

export const NAME = 'Sales Settings by Rep';

export const routes = (protectedRoute, layout) => ({
  path: PATH,
  element: layout,
  children: [
    {
      element: protectedRoute(
        <Wrapper />,
        ACCESS_PLAN_BUILDER_ABILITY,
      ),
      children: [
        {
          index: true,
          element: <SalesSettingsByRep />,
        },
      ],
    },
  ],
});
