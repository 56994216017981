import { connect } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFeatureFlag } from 'configcat-react';

import {
  featureFlagsSelector,
  hrDataSelector,
  nextStepSelector,
  repApprovedSelector,
  repEditableSelector,
  selectIsOnboardingDataUpdated,
  selectUserIdSelected,
  setOnboardingFormCompleted,
  updateRepAsync,
  pendingApprovalItemsSelector,
} from '@/redux/onboarding';
import { isAdminSelector } from '@/redux/auth';
import { formLoadingSelector } from '@/redux/loading';
import { updateRepByIdAsync } from '@/redux/reps';
import { onboardingConstants } from '@/lib';
import { composeValidationSchema } from './hrValidationSchema';
import { ProfileWizardContext, WizardFormWrapper } from '../../components';
import { CustomButtonGroup, FormSection } from '@/components/common';
import { resetOnboardingSectionData } from '@/lib/initialState';
import { useProfileEditable } from '../../components/ProfileWizard/hooks';
import { useScrollIntoView, useStableCallback } from '@/hooks';
import { adapterMapModified } from '@/lib/adapters';
import {
  IdUpload,
  W9Upload,
  W9Information,
  I9Information,
  WotcSurvey,
  DirectDeposit,
  I9Upload,
} from '../../components/HRInfo';
import PropTypes from 'prop-types';

const {
  HR_INFO_FORM_NAME,
  HR_INFO_FORM_TITLE,
  WOTC_TAX_SURVEY_SECTION_NAME,
  WOTC_TAX_SURVEY_SECTION_TITLE,
  WOTC_TAX_SURVEY_SECTION_FEATURE_FLAG_NAME,
  ID_COPY_UPLOAD_SECTION_NAME,
  ID_COPY_UPLOAD_SECTION_TITLE,
  WIZARD_TYPE_RECRUIT,
  W9_SECTION_NAME,
  W9_SECTION_TITLE,
  I9_SECTION_NAME,
  I9_SECTION_TITLE,
  DIRECT_DEPOSIT_SECTION_NAME,
  DIRECT_DEPOSIT_SECTION_TITLE,
  DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME,
  I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME,
  W9_LEGACY_FEATURE_FLAG_NAME,
  I9_LEGACY_FEATURE_FLAG_NAME,
} = onboardingConstants;

const HrForm = ({
  // Own Props
  wizardType = WIZARD_TYPE_RECRUIT,
  isPersonalWizard = true,
  userId,
  cancelToken,

  // State / Dispatch
  isUpdated,
  isRepEditable,
  isApproved,
  isAdmin,
  featureFlags,
  formLoading,
  hrData,
  nextStep,
  pendingApprovalItems,
  updateRep,
  updateRepById,
  setFormCompleted,
}) => {
  const {
    [I9_DOCUMENTS_SECTION_FEATURE_FLAG_NAME]: isI9DocumentsSectionEnabled,
    [DIRECT_DEPOSIT_SECTION_FEATURE_FLAG_NAME]: isDirectDepositSectionEnabled,
    [I9_LEGACY_FEATURE_FLAG_NAME]: isI9LegacyEnabled,
    [WOTC_TAX_SURVEY_SECTION_FEATURE_FLAG_NAME]: isWOTCTaxSurveySectionEnabled,
  } = featureFlags;

  const { value: isW9LegacyEnabled } = useFeatureFlag(
    W9_LEGACY_FEATURE_FLAG_NAME,
    false,
  );

  const [shouldScroll, setShouldScroll] = useState(false);

  const { section, setSection, showViewHistory, recruitingSeasonId } = useContext(ProfileWizardContext);

  const formRef = useRef(null);
  const wotcTaxSurveySectionRef = useRef(null);
  const idCopyUploadSectionRef = useRef(null);
  const w9SectionRef = useRef(null);
  const i9SectionRef = useRef(null);
  const directDepositSectionRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      ...hrData,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(composeValidationSchema(featureFlags)),
    context: {
      isAdmin,
      isWizard: isPersonalWizard,
      defaultValues: hrData,
    }, // TODO: rename isWizard to isPersonalWizard in validation schema
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    setValue,
    getValues,
    clearErrors,
  } = methods;

  // When evaluating form completion, emulate the "Personal Wizard" context for correct results.
  const isCompleted = composeValidationSchema(featureFlags).isValidSync({
    ...getValues(),
    // To keep the previous behavior (when "submit validator" was used, i.e. right before e04365b3ff8bed5b3cd135485e2e0682119fe695):
    //
    // Replace the condition for validating the W9 and I9 fields with the same condition as in the "submit validator".
    //
    // Note: Omit the `usePreviousW9` clause for W9 since it is already a part of the `w9Clicked` validation in the yup schema.
    w9Clicked: hrData.w9Completed || hrData.w9Submitted,
    i9Clicked: isI9LegacyEnabled ? (hrData.i9Completed || hrData.i9Submitted) : hrData.i9Completed,
  }, {
    context: {
      isAdmin: false,
      isWizard: true,
      defaultValues: hrData,
    },
  });

  const { isEditable, canEditField } = useProfileEditable({
    wizardType,
    isPersonalWizard,
    isAdmin,
    isApproved,
    isRepEditable,
    pendingApprovalItems,
  });

  const handleChange = useCallback((event) => {
    const { name, type, files, checked } = event.target;
    let { value } = event.target;

    value = type === 'file' ? files[0] : value;
    value = type === 'checkbox' ? checked : value;

    setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: type !== 'file' });

    resetOnboardingSectionData(name, value, (name, value) => {
      setValue(name, value, { shouldDirty: true }); // Skip validation, but recompute the `isDirty` when resetting sub-fields.
      clearErrors(name); // Clear errors for sub-fields if any.
    });
  }, [
    setValue,
    clearErrors,
  ]);

  const handleSubmitForm = useStableCallback((onSuccess) => {
    const updateRepCallback = userId ? updateRepById : updateRep;
    const sendingData = adapterMapModified['combined'](getValues());

    updateRepCallback({
      data: {
        ...sendingData,
        ...(userId && { userId }),
        ...(recruitingSeasonId && { recruitingSeasonId: recruitingSeasonId }),
      },
      ...(cancelToken && { cancelToken }),
      successCallback: onSuccess ?? (() => {}),
    });
  });

  const handleResetForm = useCallback(() => {
    reset({
      ...hrData,
    });
  }, [reset, hrData]);

  useEffect(() => {
    setFormCompleted({ formId: HR_INFO_FORM_NAME, isCompleted });
  }, [isCompleted, setFormCompleted]);

  useEffect(() => {
    if (isUpdated) {
      handleResetForm();
    }
    // we don't want to reset the form when handleResetForm changes as we might lose data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
    if (nextStep) {
      if (isEditable) {
        handleSubmit(
          () => handleSubmitForm(nextStep.applyTransition),
          () => nextStep.declineTransition(),
        )();
      } else {
        nextStep.applyTransition();
      }
    }
  }, [nextStep, isEditable, handleSubmit, handleSubmitForm]);

  /**
   * Initially `formLoading` is undefined which interprets as false
   * and React mounts a form component.
   *
   * However, in a moment `formLoading` becomes true indicating the real data load.
   * React unmounts the form component and mount Loader instead.
   *
   * And only when data is loaded completely and `formLoading becomes undefined/false again
   * we're ready to scroll to desired form section.
   */
  useEffect(() => {
    if (!shouldScroll && !formLoading) {
      setShouldScroll(true);
    }
  }, [shouldScroll, formLoading]);

  useScrollIntoView(
    shouldScroll && {
      [WOTC_TAX_SURVEY_SECTION_NAME]: wotcTaxSurveySectionRef,
      [ID_COPY_UPLOAD_SECTION_NAME]: idCopyUploadSectionRef,
      [W9_SECTION_NAME]: w9SectionRef,
      [I9_SECTION_NAME]: i9SectionRef,
      [DIRECT_DEPOSIT_SECTION_NAME]: directDepositSectionRef,
    }[section],
    (parent) => !(parent === formRef.current.parentElement),
    () => setSection(null),
  );

  return (
    <WizardFormWrapper ref={formRef} title={HR_INFO_FORM_TITLE} isLoading={formLoading}>
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(() => handleSubmitForm())}>
          <FormSection
            ref={w9SectionRef}
            title={W9_SECTION_TITLE}
            viewHistory={isAdmin && !isPersonalWizard}
            onViewHistory={() => {
              showViewHistory(W9_SECTION_NAME);
            }}
          >
            {isW9LegacyEnabled ? (
              <W9Upload
                isPersonalWizard={isPersonalWizard}
                hrData={hrData}
                canEditField={canEditField}
                userId={userId}
                onChangeHandler={handleChange}
              />
            ) : (
              <W9Information
                userId={userId}
                isAdmin={isAdmin}
                canEditField={canEditField}
                onChangeHandler={handleChange}
                isPersonalWizard={isPersonalWizard}
                parentRegister={methods.register} // TODO: temporary. Needs refactoring: inner form should be moved to a separate component.
              />
            )}
          </FormSection>

          {isI9DocumentsSectionEnabled && (
            <FormSection
              ref={i9SectionRef}
              title={I9_SECTION_TITLE}
              viewHistory={isAdmin && !isPersonalWizard}
              onViewHistory={() => {
                showViewHistory(I9_SECTION_NAME);
              }}
            >
              {isI9LegacyEnabled ? (
                <I9Upload
                  isPersonalWizard={isPersonalWizard}
                  hrData={hrData}
                  canEditField={canEditField}
                  userId={userId}
                />
              ) : (
                <I9Information
                  userId={userId}
                  isAdmin={isAdmin}
                  canEditField={canEditField}
                  isPersonalWizard={isPersonalWizard}
                  setHrFormValue={setValue}
                />
              )}
            </FormSection>
          )}

          {isWOTCTaxSurveySectionEnabled && (
            <FormSection
              ref={wotcTaxSurveySectionRef}
              title={WOTC_TAX_SURVEY_SECTION_TITLE}
              viewHistory={isAdmin && !isPersonalWizard}
              onViewHistory={() => {
                showViewHistory(WOTC_TAX_SURVEY_SECTION_NAME);
              }}
            >
              <WotcSurvey canEditField={canEditField} />
            </FormSection>
          )}

          <FormSection
            ref={idCopyUploadSectionRef}
            title={ID_COPY_UPLOAD_SECTION_TITLE}
            viewHistory={isAdmin && !isPersonalWizard}
            onViewHistory={() => {
              showViewHistory(ID_COPY_UPLOAD_SECTION_NAME);
            }}
          >
            <IdUpload
              userId={userId}
              canEditField={canEditField}
              onChangeHandler={handleChange}
              passportExpirationDate={hrData?.passportExpirationDate}
            />
          </FormSection>

          {isDirectDepositSectionEnabled && (
            <FormSection
              ref={directDepositSectionRef}
              title={DIRECT_DEPOSIT_SECTION_TITLE}
              viewHistory={isAdmin && !isPersonalWizard}
              onViewHistory={() => {
                showViewHistory(DIRECT_DEPOSIT_SECTION_NAME);
              }}
            >
              <DirectDeposit
                canEditField={canEditField}
                onChangeHandler={handleChange}
              />
            </FormSection>
          )}

          {isEditable && (
            <CustomButtonGroup
              orientation="right"
              onCancelClick={handleResetForm}
              disabledCancel={!isDirty}
              withSubmit
            />
          )}
        </form>
      </FormProvider>
    </WizardFormWrapper>
  );
};

HrForm.propTypes = {
  wizardType: PropTypes.string,
  isPersonalWizard: PropTypes.bool,
  userId: PropTypes.number,
  isUpdated: PropTypes.bool,
  isApproved: PropTypes.bool,
  isAdmin: PropTypes.bool,
  featureFlags: PropTypes.object,
  formLoading: PropTypes.bool,
  hrData: PropTypes.object,
  updateRep: PropTypes.func,
  updateRepById: PropTypes.func,
  setFormCompleted: PropTypes.func,
  nextStep: PropTypes.shape({
    applyTransition: PropTypes.func.isRequired,
    declineTransition: PropTypes.func.isRequired,
  }),
  isRepEditable: PropTypes.bool,
  cancelToken: PropTypes.object,
  pendingApprovalItems: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isUpdated: selectIsOnboardingDataUpdated(state),
  selectedRepId: selectUserIdSelected(state),
  isRepEditable: repEditableSelector(state),
  isApproved: repApprovedSelector(state),
  isAdmin: isAdminSelector(state),
  featureFlags: featureFlagsSelector(state),
  formLoading: formLoadingSelector(state),
  hrData: hrDataSelector(state),
  nextStep: nextStepSelector(state),
  pendingApprovalItems: pendingApprovalItemsSelector(state),
});

const mapDispatchToProps = {
  updateRep: updateRepAsync.request,
  updateRepById: updateRepByIdAsync.request,
  setFormCompleted: setOnboardingFormCompleted,
};

export default connect(mapStateToProps, mapDispatchToProps)(HrForm);
