import { createReducer } from '@/redux/root';
import { requestSalesTeamsAsync, nameSpace, requestSalesAreasAsync } from '@/redux/sales-teams';

const manageSalesTeamsInitialState = {
  salesTeams: [],
  salesTeamsTotal: 0,
  salesAreas: [],
  salesAreasTotal: 0,
};

export const manageSalesTeamsReducer = createReducer(nameSpace, manageSalesTeamsInitialState, {
  [requestSalesTeamsAsync.success]: ({ state, action: { payload } }) => {
    state.salesTeams = payload.data.map(({ attributes }) => ({
      sales_team_id: attributes.team_id,
      ...attributes,
    }));
    state.salesTeamsTotal = payload.meta.total;
  },
  [requestSalesAreasAsync.success]: ({ state, action: { payload } }) => {
    state.salesAreas = payload.salesAreas.map(({ attributes }) => ({
      sales_area_id: attributes.area_id,
      ...attributes,
    }));
    state.salesAreasTotal = payload.salesAreasTotal;
  },
});

