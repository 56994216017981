// Bootstrap @aptive-env packages by initializing their settings.
//
// Since ESModules (simplifying, every single file is ESModule) are executed and
// cached during import, this file must be imported before any other @aptive-env package
// files to avoid accessing settings before they are initialized during import.
import './bootstrap';

// TODO: Important! Import App before StoreProvider to avoid weird and uninformative errors caused by circular dependencies.
//       Error messages can be different, such as "cannot access {property} of undefined" or "cannot access {property} before initialization.
//       A stack trace is also weird: you never find the printed call trace in the app.
//       Summarizing: if you change the imports order and get a weird error - it's likely caused by circular dependencies import,
//       i.e. module A imports module B which imports module A again.
//       Resolve the circular dependency if dependency in the code that you just wrote, or revert the change.
//       See https://medium.com/visual-development/how-to-fix-nasty-circular-dependency-issues-once-and-for-all-in-javascript-typescript-a04c987cf0de
import App from './App';
import { ConfigCatWrapper } from '@/configcat';
import { StoreProvider } from '@/redux/root';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <ConfigCatWrapper>
          <App />
        </ConfigCatWrapper>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//
// eslint-disable-next-line no-console
reportWebVitals(console.log);
