import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useRequireAuth = (isAuthenticated) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/login?redirectUrl=${location.pathname}`);
    }
  }, [isAuthenticated, navigate, location.pathname]);
};
