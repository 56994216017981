import { useStableCallback } from '@/hooks';
import { onboardingConstants, DOCUMENT_TYPES } from '@/lib/constants';
import { useMemo } from 'react';
import { isRecruitWizard } from '../../lib';

const {
  SALES_RANK,
  HAS_CV_BASED_PAY,
  UPFRONT_PAY_NAME,
  RENT_DEDUCTION_NAME,
  RENT_DEDUCTION_REASON_NAME,
  UNIFORM_DEDUCTION_NAME,
  DRIVER_LICENSE,
  PASSPORT_PICTURE,
  PASSPORT_EXPIRATION_DATE,
  PROFILE_PICTURE,
  SOCIAL_SECURITY_CARD,
  USES_TYPE,
  DOCUMENT_TYPE_W9,
  DOCUMENT_TYPE_I9,
  USE_PREVIOUS_W9_NAME,
  HAS_REP_EXPERIENCE,
  COMPANY_YEARS_SOLD,
  COMPANY_ACCOUNT_NUMBERS,
  COMPANY_NAME,
  LAST_INDUSTRY_NAME,
  REP_EXPERIENCES_DATA_NAME,
  LAST_INDUSTRY_OTHER_NAME,
  SOCIAL_SECURITY_NUMBER,
  ACTUAL_START_DATE,
  ACTUAL_END_DATE,
} = onboardingConstants;

const useProfileEditable = ({
  wizardType,
  isPersonalWizard,
  isAdmin,
  isRepEditable,
  pendingApprovalItems,
}) => {
  const isEditable = useMemo(() => {
    // The Admin can always edit a Recruit's profile.
    if (isRecruitWizard(wizardType) && isAdmin && isRepEditable) {
      return true;
    }

    // A user of any role can always edit their own profile.
    if (isPersonalWizard) {
      return true;
    }

    // By default, profile editing is disabled for security reasons.
    return false;
  }, [
    wizardType,
    isPersonalWizard,
    isAdmin,
    isRepEditable,
  ]);

  const canEditField = useStableCallback((fieldName) => {
    const isSsnField = fieldName === SOCIAL_SECURITY_NUMBER;

    const isPayField = [
      SALES_RANK,
      HAS_CV_BASED_PAY,
      UPFRONT_PAY_NAME,
      RENT_DEDUCTION_NAME,
      RENT_DEDUCTION_REASON_NAME,
      UNIFORM_DEDUCTION_NAME,
    ].includes(fieldName);

    const isExperienceField = [
      HAS_REP_EXPERIENCE,
      REP_EXPERIENCES_DATA_NAME,
      COMPANY_YEARS_SOLD,
      COMPANY_ACCOUNT_NUMBERS,
      COMPANY_NAME,
      LAST_INDUSTRY_NAME,
      LAST_INDUSTRY_OTHER_NAME,
    ].includes(fieldName);

    const isDocumentField = [
      DRIVER_LICENSE,
      PASSPORT_PICTURE,
      PASSPORT_EXPIRATION_DATE,
      PROFILE_PICTURE,
      SOCIAL_SECURITY_CARD,
      DOCUMENT_TYPE_W9,
      DOCUMENT_TYPE_I9,
    ].includes(fieldName);

    const isActualDateField = [
      ACTUAL_START_DATE,
      ACTUAL_END_DATE,
    ].includes(fieldName);

    // Bail early if the profile isn't editable at all.
    if (!isEditable) {
      return false;
    }

    // No one except for the Admin who edits a Recruit's profile can edit pay details.
    if (!(isRecruitWizard(wizardType) && isAdmin) && isPayField) {
      return false;
    }

    // No one except for the Admin who edits a Recruit's profile can edit experience.
    if (!(isRecruitWizard(wizardType) && isAdmin) && isExperienceField) {
      return false;
    }

    // No one except for the Admin who edits a Recruit's profile can edit actual housing dates.
    if (!(isRecruitWizard(wizardType) && isAdmin) && isActualDateField) {
      return false;
    }

    // No one except for the user who edits his own profile can edit SSN.
    if (!isPersonalWizard && isSsnField) {
      return false;
    }

    if (isDocumentField) {
      const fieldToDocumentTypeMap = {
        [DRIVER_LICENSE]: DOCUMENT_TYPES.DL_IMG,
        [PASSPORT_PICTURE]: DOCUMENT_TYPES.PASSPORT_IMG,
        [PROFILE_PICTURE]: DOCUMENT_TYPES.PROFILE_IMG,
        [SOCIAL_SECURITY_CARD]: DOCUMENT_TYPES.SS_IMG,
        [DOCUMENT_TYPE_W9]: DOCUMENT_TYPES.W9_IMG,
        [DOCUMENT_TYPE_I9]: DOCUMENT_TYPES.I9_IMG,
        [PASSPORT_EXPIRATION_DATE]: DOCUMENT_TYPES.PASSPORT_IMG,
      };

      const documentType = fieldToDocumentTypeMap[fieldName];

      // Recruits who submitted their document for approval
      // can't upload newer versions of their documents.
      if (
        isRecruitWizard(wizardType)
        && isPersonalWizard
        && documentType
        && pendingApprovalItems?.includes(documentType)
      ) {
        return false;
      }
    }

    if (fieldName === USES_TYPE) {
      const isPassportPendingApproval = pendingApprovalItems?.includes(DOCUMENT_TYPES.PASSPORT_IMG);
      const isDriverLicensePendingApproval = pendingApprovalItems?.includes(DOCUMENT_TYPES.DL_IMG);
      const isSocialSecurityPendingApproval = pendingApprovalItems?.includes(DOCUMENT_TYPES.SS_IMG);

      if (isPassportPendingApproval || (isDriverLicensePendingApproval && isSocialSecurityPendingApproval)) {
        return false;
      }
    }

    // By default, any field can be edited.
    return true;
  });

  return {
    isEditable,
    canEditField,
  };
};

export default useProfileEditable;
