const calculateWizardProgress = ({
  menuItems,
  isApproved,
  workdayComplete,
}) => {
  let progress;
  let message;

  const { passed, total } = menuItems.wizard
    .filter(({ shouldDisplay }) => shouldDisplay)
    .reduce(
      ({ passed, total }, { completed }) => ({
        passed: completed ? ++passed : passed,
        total: ++total,
      }),
      { passed: 0, total: 0 },
    );

  progress = Math.floor(passed / total * 100);
  progress = isFinite(progress) ? progress : 0;

  const shouldDowngradeProgress =
    (!isApproved || !workdayComplete)
    && progress === 100;

  if (shouldDowngradeProgress) {
    progress = 95;
    message = '*95% until approved by Admins';
  }

  return {
    progress,
    message,
  };
};

export default calculateWizardProgress;
