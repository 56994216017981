// Import settings directly from source files to avoid importing (and caching)
// other ESModules (files) that rely on those settings.
import comsUISettings from '@aptive-env/coms-ui/settings';

import HandleCookies from '@/lib/api/handleCookies';

comsUISettings.configure({
  routePrefix: process.env.REACT_APP_COMS_UI_ROUTE_PREFIX ?? '',
  apiUrl: process.env.REACT_APP_COMS_UI_API_URL ?? '',
  JWT: () => HandleCookies.get('tokenPayload'),
  configCatSDKKey: process.env.REACT_APP_COMS_UI_CONFIGCAT_SDK_KEY ?? '',
});
