import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Select } from '@/components/common';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';
import { repsSelector, requestRepsAsync, salesSettingsByRepSelector } from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';

const { REP_LABEL, TARGET_ID_VALUE } = planBuilderConstants;

const RepSelect = () => {
  const reps = useSelector(repsSelector);
  const salesSettingsByRep = useSelector(salesSettingsByRepSelector);

  const options = useMemo(() => {
    if (!reps) {
      return [];
    }
    const repIds = salesSettingsByRep.map(({ [TARGET_ID_VALUE]:target_id }) => target_id);

    const values = reps
      .filter(({ id }) => !repIds.includes(id))
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }));

    return [
      ...values,
    ];
  }, [reps, salesSettingsByRep]);

  const [timeoutId, setTimeoutId] = useState(null);

  const dispatch = useDispatch();

  const onSearch = (name) => {
    clearTimeout(timeoutId);

    if (!name) {
      return;
    }

    const delayedRequest = setTimeout(() => {
      const params = {
        filter: {
          search: name,
          is_active: true,
        },
      };

      dispatch(requestRepsAsync.request(params));
    }, 500); // Adjust the delay time as needed

    setTimeoutId(delayedRequest);
  };

  return (
    <div className="flex flex-col w-64">
      <div className="flex items-center">
        <div className="flex text-sm font-medium text-gray-700">
          <label>
            {REP_LABEL}
            <span className="text-red-500 pl-1">{'*'}</span>
          </label>
        </div>
      </div>
      <div className="mt-0.5 block rounded-md shadow-sm">
        <Controller
          name={TARGET_ID_VALUE}
          render={({ field: { onChange, value, name } }) => (
            <Select
              {...{
                name,
                onChange,
                value,
                options,
              }}
              required
              onInputChange={onSearch}
              placeholder="Start to type"
            />
          )}
        />
      </div>
    </div>
  );
};

export default RepSelect;
