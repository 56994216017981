import { Link, useLocation } from 'react-router-dom';
import { abilityConstants, dashboardConstants, userCan } from '@/lib';
import { housingConstants } from '@/modules/Housing/lib/constants';
import { splitPathBySlashes, startWith } from '@/lib/utils';
import classNames from 'classnames';
import { adminToolPages } from '@/modules/AdminTools/routing';
import { housingPages } from '@/modules/Housing/routing';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectDefaultTeamId } from '@/redux/auth';
import { adminToolsConstants } from '@/modules/AdminTools/lib/constants';
import { useFeatureFlag } from 'configcat-react';
import NavigationMenu from './NavigationMenu';

export const adminToolPaths = adminToolPages.map(({ PATH }) => PATH);

export const housingPaths = housingPages.map(({ PATH }) => PATH);

const {
  BRANCH_MANAGER_ROLE,
  TEAM_LEADER_ROLE,
  SENIOR_TEAM_LEADER_ROLE,
  DIVISION_MANAGER_ROLE,
  REGIONAL_MANAGER_ROLE,
  SENIOR_REGIONAL_ROLE,
  VP_OF_SALES_ROLE,
  PARTNERSHIP_ROLE,
  SPT_MODULE_FEATURE_FLAG_NAME,
  ARO_MODULE_FEATURE_FLAG_NAME,
  ARO_PLANNING_PAGE_FEATURE_FLAG_NAME,
  LICENSING_MODULE_FEATURE_FLAG_NAME,
  COMS_MODULE_FEATURE_FLAG_NAME,
} = dashboardConstants;

const {
  ACCESS_ONB_ABILITY,
  ACCESS_PLAN_BUILDER_ABILITY,
  ACCESS_SPT_ABILITY,
  VIEW_AREAS_ABILITY,
  ACCESS_HOUSING_ABILITY,
  ACCESS_ARO_ABILITY,
  ACCESS_LICENSING_ABILITY,
  ACCESS_LICENSING_PROFILE_ABILITY,
  ACCESS_COMS_ABILITY,
} = abilityConstants;

const TopNav = ({ user }) => {
  const location = useLocation();
  const defaultTeam = useSelector(selectDefaultTeamId);
  const { value: isSPTModuleEnabled } = useFeatureFlag(
    SPT_MODULE_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isAroModuleEnabled } = useFeatureFlag(
    ARO_MODULE_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isAroPlanningPageEnabled } = useFeatureFlag(
    ARO_PLANNING_PAGE_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isLicensingModuleEnabled } = useFeatureFlag(
    LICENSING_MODULE_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isCOMSModuleEnabled } = useFeatureFlag(
    COMS_MODULE_FEATURE_FLAG_NAME,
    false,
  );

  const isCurrentLocation = (path) => {
    const splittedMenuPath = splitPathBySlashes(path);
    const splittedPathname = splitPathBySlashes(location.pathname);

    return splittedMenuPath[0] === splittedPathname[0];
  };

  const isTeamLeader = (user) => [
    TEAM_LEADER_ROLE,
    SENIOR_TEAM_LEADER_ROLE,
    DIVISION_MANAGER_ROLE,
  ].includes(user?.role);

  const isRegional = (user) => [
    REGIONAL_MANAGER_ROLE,
    SENIOR_REGIONAL_ROLE,
    VP_OF_SALES_ROLE,
    PARTNERSHIP_ROLE,
  ].includes(user?.role);

  const isBranchManager = (user) => [
    BRANCH_MANAGER_ROLE,
  ].includes(user?.role);

  const aroNavOptions = [
    {
      isDisplayed: true,
      name: 'Map',
      path: '/aro/map',
    },
    ...(isAroPlanningPageEnabled ? [{
      isDisplayed: true,
      name: 'Planning',
      path: '/aro/planning',
    }] : []),
    {
      isDisplayed: true,
      name: 'Calendar',
      path: '/aro/calendar',
    },
  ];

  const licensingNavOptions = [
    {
      isDisplayed: true,
      name: 'Applications',
      path: '/licensing/applications',
    },
    {
      isDisplayed: true,
      name: 'Requirements',
      path: '/licensing/requirements',
    },
    {
      isDisplayed: true,
      name: 'Sales Representatives',
      path: '/licensing/sales-reps',
    },
  ];

  const menuItems = [
    {
      ability: ACCESS_ONB_ABILITY,
      name: 'Onboarding',
      path: '/onboarding',
    },
    {
      ability: ACCESS_SPT_ABILITY,
      name: 'Sales planning tool',
      path: userCan(user, VIEW_AREAS_ABILITY)
        ? '/sales-planning'
        : (
          isRegional(user)
            ? '/sales-planning/teams'
            : (
              (isTeamLeader(user) || isBranchManager(user)) && defaultTeam
                ? `/sales-planning/team/${defaultTeam}`
                : '/sales-planning/teams'
            )
        ),
    },
    ...(isSPTModuleEnabled ? [
      {
        ability: ACCESS_SPT_ABILITY,
        name: 'Sales Planning Tool (new)',
        path: '/sales-planning-tool',
      },
    ] : []),
    {
      ability: ACCESS_PLAN_BUILDER_ABILITY,
      name: adminToolsConstants.ADMIN_TOOLS,
      path: adminToolPaths[0],
      isCurrentLocation: () => adminToolPaths.some((path) => isCurrentLocation(path)),
    },
    {
      ability: ACCESS_HOUSING_ABILITY,
      name: housingConstants.HOUSING,
      path: housingPaths[0],
      isCurrentLocation: () => housingPaths.some((path) => isCurrentLocation(path)),
    },
    ...(isAroModuleEnabled ? [
      {
        ability: ACCESS_ARO_ABILITY,
        render: () => <NavigationMenu key="Routes" name="Routes" navigationOption={aroNavOptions} isBrand />,
      },
    ] : []),
    ...(isLicensingModuleEnabled ? [
      {
        ability: ACCESS_LICENSING_ABILITY,
        render: () => <NavigationMenu key="Licensing" name="Licensing" navigationOption={licensingNavOptions} isBrand />,
      },
      {
        ability: ACCESS_LICENSING_PROFILE_ABILITY,
        name: 'Licensing Profile',
        path: '/licensing/profile',
      },
    ] : []),
    ...(isCOMSModuleEnabled ? [
      {
        ability: ACCESS_COMS_ABILITY,
        name: 'COMS',
        path: startWith(process.env.REACT_APP_COMS_UI_ROUTE_PREFIX, '/'),
      },
    ] : []),
  ].filter(({ ability }) => userCan(user, ability));

  return menuItems.length > 0 && (
    <nav className="hidden sm:block bg-gray-100 border-b">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-10">
          <div className="flex sm:space-x-8">
            {menuItems.map((item) => (
              item.render
                ? item.render(item)
                : (
                  <Link
                    to={item.path}
                    key={item.name}
                    className={classNames(
                      (item.isCurrentLocation ? item.isCurrentLocation() : isCurrentLocation(item.path))
                        ? 'border-gray-900 text-gray-900 border-b-4'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-700 border-b-2',
                      'inline-flex items-center px-1 pt-1 text-sm font-medium',
                    )}
                  >
                    {item.name}
                  </Link>
                )
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

TopNav.propTypes = {
  user: PropTypes.object,
};

export default TopNav;
