import * as yup from 'yup';
import { ADDRESS_VALIDATION_MESSAGE } from '@/lib/validations';

yup.addMethod(yup.string, 'checkAddress', function (errorMessage) {
  return this.test('test-address-does-not-contain-email-address', errorMessage, function (value) {
    const {path, createError} = this;

    return (
      (!value || value.trim().length === 0)
      || value.match(/\S*@\S+\.\S+/) === null
      || createError({path, message: errorMessage || ADDRESS_VALIDATION_MESSAGE})
    );
  });
});
