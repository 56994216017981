import { trimStart } from 'lodash-es';

export const formatNumberToCurrencyString = (value, numberOfFractionDigits) => {
  numberOfFractionDigits = numberOfFractionDigits ? numberOfFractionDigits : 0;
  const isNegative = value < 0;
  const absoluteValue = Math.abs(value);

  const fractionString = Number(absoluteValue).toLocaleString(
    process.env.REACT_APP_LOCALE,
    { maximumFractionDigits: numberOfFractionDigits, minimumFractionDigits: numberOfFractionDigits },
  );

  return `${isNegative ? '-' : ''}$${fractionString}`;
};

/**
 * @deprecated
 * @todo: SPT:REMOVE
 */
export const addPluralS = (value, count) => { // TODO: SPT:REMOVE
  return count > 1 ? `${value}s` : value;
};

export const startWith = (string, prefix) => prefix + trimStart(string, prefix);
