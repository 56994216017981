export const onboardingSidebar = {
  PERSONAL_INFO_STEP_ID: 1,
  HOUSING_VEHICLE_STEP_ID: 2,
  UNIFORM_STEP_ID: 3,
  LICENSING_STEP_ID: 4,
  HR_STEP_ID: 5,
  DOCUMENTS_STEP_ID: 7,
  WORKDAY_TASK_STEP_ID: 8,
  ATTACHMENTS_STEP_ID: 9,
  ADMIN_VIEW_STEP_ID: 10,
};
