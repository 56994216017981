import { SearchDropdown, CustomFormElement } from '@/components/common';
import { dashboardConstants } from '@/lib';
import { addFsExcludeClass } from '@/lib/utils';

const {
  RECRUITER_INFO,
  SELECT_RECRUITER_LABEL,
  PARTNERSHIP,
  REGIONAL_MANAGER,
  DIVISION_MANAGER,
  SENIOR_TEAM_LEADER,
  RECRUITING_OFFICE_LABEL,
  RECRUITER_NAME,
  SENIOR_TEAM_LEADER_NAME,
  DIVISION_MANAGER_NAME,
  REGIONAL_MANAGER_NAME,
  PARTNERSHIP_NAME,
  RECRUITING_OFFICE_NAME,
} = dashboardConstants;

const RecruiterInfo = ({
  onChangeField,
  recruiters,
  recruitingOffices,
  errors,
  register,
  recruiterManagersIsLoading,
}) => {
  return (
    <div className="pt-4">
      <div>
        <h3 className="font-medium leading-6 text-md text-aptivegreen">
          {RECRUITER_INFO}
        </h3>
      </div>
      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <SearchDropdown
            required
            label={`*${SELECT_RECRUITER_LABEL}`}
            items={recruiters}
            displayProp="label"
            onChange={(item) => onChangeField(RECRUITER_NAME, item.value)}
            error={errors?.[RECRUITER_NAME]}
            className={addFsExcludeClass('h-9')}
          />
        </div>

        <CustomFormElement
          required
          disabled
          label={SENIOR_TEAM_LEADER}
          type="text"
          name={SENIOR_TEAM_LEADER_NAME}
          id={SENIOR_TEAM_LEADER_NAME}
          loading={recruiterManagersIsLoading}
          error={errors?.[SENIOR_TEAM_LEADER_NAME]}
          register={register}
          className={addFsExcludeClass()}
        />

        <CustomFormElement
          required
          disabled
          label={DIVISION_MANAGER}
          type="text"
          name={DIVISION_MANAGER_NAME}
          id={DIVISION_MANAGER_NAME}
          loading={recruiterManagersIsLoading}
          error={errors?.[DIVISION_MANAGER_NAME]}
          register={register}
          className={addFsExcludeClass()}
        />

        <CustomFormElement
          required
          disabled
          label={REGIONAL_MANAGER}
          type="text"
          name={REGIONAL_MANAGER_NAME}
          id={REGIONAL_MANAGER_NAME}
          loading={recruiterManagersIsLoading}
          error={errors?.[REGIONAL_MANAGER_NAME]}
          register={register}
          className={addFsExcludeClass()}
        />

        <CustomFormElement
          required
          disabled
          label={PARTNERSHIP}
          type="text"
          name={PARTNERSHIP_NAME}
          id={PARTNERSHIP_NAME}
          loading={recruiterManagersIsLoading}
          error={errors?.[PARTNERSHIP_NAME]}
          register={register}
          className={addFsExcludeClass()}
        />

        <div className="sm:col-span-3">
          <SearchDropdown
            required
            label={`*${RECRUITING_OFFICE_LABEL}`}
            items={recruitingOffices}
            displayProp="label"
            onChange={(item) => onChangeField(RECRUITING_OFFICE_NAME, item.value)}
            error={errors?.[RECRUITING_OFFICE_NAME]}
            className="h-9"
          />
        </div>
      </div>
    </div>
  );
};

export default RecruiterInfo;
