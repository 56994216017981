import React, { useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {ErrorBox, Loader} from '@/components/common';

import {onboardingConstants} from '@/lib';
import {attachmentsErrorSelector, requestAttachmentsAsync, attachmentsSelector} from "@/redux/reps";
import {attachmentsIsLoadingSelector} from "@/redux/loading";
import AttachmentsListItem from './AttachmentsListItem';

const {
  PASSPORT_LABEL,
  DRIVER_LICENSE_LABEL,
  SOCIAL_SECURITY_LABEL,
  SIGNATURE_LABEL,
  PROFILE_PICTURE_LABEL,
  W9_LABEL,
  I9_LABEL,
} = onboardingConstants;

const AttachmentsList = (
  {
    userId,
    isPersonalWizard,
    attachments,
    error,
    loading,
    requestAttachments
  }) => {
  const [approvedImages, setApprovedImages] = useState([]);
  const [pendingImages, setPendingImages] = useState([]);
  const [otherImages, setOtherImages] = useState([]);

  const handleDownloadClick = (image) => window.open(image.link, '_blank')?.focus();

  useEffect(() => {
    // null is a marker that user id isn't presented yet, so we shouldn't request attachments now
    if (userId !== null) {
      requestAttachments({ userId, isPersonalWizard });
    }
  }, [requestAttachments, userId, isPersonalWizard]);

  useEffect(() => {
    let approvedList = [];
    let pendingList = [];
    let otherList = [];

    if (attachments) {
      attachments.forEach((attachment) => {
        let name;

        switch (attachment.type) {
          case 'profilePicture':
            name = PROFILE_PICTURE_LABEL;
            break;
          case 'passportPicture':
            name = PASSPORT_LABEL;
            break;
          case 'driverLicensePicture':
            name = DRIVER_LICENSE_LABEL;
            break;
          case 'socialSecurityCardPicture':
            name = SOCIAL_SECURITY_LABEL;
            break;
          case 'signaturePicture':
            name = SIGNATURE_LABEL;
            break;
          case 'w9Picture':
            name = W9_LABEL;
            break;
          case 'i9Picture':
            name = I9_LABEL;
            break;
        }

        if (attachment?.url) {
          const image = { link: attachment.url, name: name, docusignDocumentType: attachment?.docusignDocumentType };
          switch (attachment.status) {
            case 'approved':
              approvedList.push(image);
              break;
            case 'pending':
              pendingList.push(image);
              break;
            default:
              otherList.push(image);
          }
        }
      });
    }

    setApprovedImages(approvedList);
    setPendingImages(pendingList);
    setOtherImages(otherList);
  }, [attachments]);

  return loading
    ? <Loader />
    : (
      <>
        {error && (
          <div className="mb-2">
            <ErrorBox message={error} />
          </div>
        )}
        {approvedImages?.length > 0 && (
          <div classname="mb-4">
            <h3 className="font-bold text-aptivegreen mb-2">Approved Documents</h3>
            {approvedImages.map((image, i) => (
              <AttachmentsListItem
                image={image}
                key={`approved_image_${i}`}
                onDownloadClick={handleDownloadClick}
                userId={userId}
              />
            ))}
          </div>
        )}
        {pendingImages?.length > 0 && (
          <div classname="mb-4">
            <h3 className="font-bold text-aptivegreen mb-2">Pending Approval</h3>
            {pendingImages.map((image, i) => (
              <AttachmentsListItem
                image={image}
                key={`pending_image_${i}`}
                onDownloadClick={handleDownloadClick}
                userId={userId}
              />
            ))}
          </div>
        )}
        {otherImages?.length > 0 && (
          <div classname="mb-4">
            <h3 className="font-bold text-aptivegreen mb-2">Other Documents</h3>
            {otherImages.map((image, i) => (
              <AttachmentsListItem
                image={image}
                key={`other_image_${i}`}
                onDownloadClick={handleDownloadClick}
                userId={userId}
              />
            ))}
          </div>
        )}
        {approvedImages.length === 0 && pendingImages.length === 0 && otherImages.length === 0 && (
          <div className="text-center">
            {onboardingConstants.NO_ATTACHMENTS_FOUND}
          </div>
        )}
      </>
    );
};

const mapStateToProps = (state, { userId }) => {
  return {
    loading: attachmentsIsLoadingSelector(state),
    error: attachmentsErrorSelector(state, userId),
    attachments: attachmentsSelector(state, userId),
  };
};

const mapDispatchToProps = {
  requestAttachments: requestAttachmentsAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsList);
